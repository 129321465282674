/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiSceneInterfaceV2.1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer Scene API V2.1
 *
 * @module ApiSceneInterface
 * @author ShapeDiver <contact@shapediver.com>
 */


////////////
////////////
//
// Scene Interface
//
////////////
////////////

/**
  * Scene asset item format, see {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT} for values
  * @typedef {String} module:ApiSceneInterface~ApiSceneInterface#SceneAssetItemFormat
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem}
  */

/**
  * Anchor definition - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is ANCHOR.
  *
  * Anchors allow to attach DOM elements to projected 3D locations of the scene. Whenever the camera moves and projected 3D location changes,
  * the viewer emits events which report the updated position of the projected 3D location, such that the DOM element's position can be updated.
  * The viewer provides some predefined types of anchors to simplify usage of basic anchored DOM elements.
  * When using one of these types, the DOM elements are created and managed by the viewer.
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#Anchor
  * @property {String} version - Always '1.0'
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} location - The 3D point defining the location of the anchor
  * @property {String[]} [viewports] - optional array of viewport runtime ids, for which the anchors shall report their position, defaults to all viewports
  * @property {String} [format] - optional format description of the anchor object, this property will be passed on to events related to the anchor,
  *                               predefined formats include {@link module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatText text}
  *                               and {@link module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatImage image}
  * @property {Object} [data] - optional data of the anchor object, this property will be passed on to events related to the anchor,
  *                             predefined objects include {@link module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatText AnchorDataFormatText}
  *                             and {@link module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatImage AnchorDataFormatImage}
  * @property {Boolean} [hideable=true] - optional boolean on wheter the anchor is hideable. Not attempting to hide the anchor improves the performance,
  *                                       as visibility checks take time.
  * @property {module:ApiSceneInterface~ApiSceneInterface#BoundingBox|module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType|module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [intersectionTarget=null] - optional intersection target. This target is
  *                             used for the visibility test. As intersecting with the whole scene takes time, specifying a target to intersect againt 
  *                             improves the performance. Can be a {@link module:ApiSceneInterface~ApiSceneInterface#BoundingBox BoundingBox}, a
  *                             {@link module:ApiSceneInterface~ApiSceneInterface#ScenePathType ScenePathType} or an array of
  *                             {@link module:ApiSceneInterface~ApiSceneInterface#ScenePathType ScenePathType}.
  */

/**
 * Anchor data object for format 'tag2d'
 *
 * (legacy support, use format 'text' instead)
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatTag2D
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [color='black'] - text color to use
 * @property {String} text - text to display at projected 3D location
 */

/**
 * Anchor data object for format 'text'
 *
 * Displays text at the anchor location
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatText
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [color='black'] - text color to use
 * @property {String} text - text to display at projected 3D location
 * @property {Boolean} [hidden=true] if it can be hidden by other objects
 * @property {String} [positioning.horizontal=center] left, center, right
 * @property {String} [positioning.vertical=center] bottom, center, top
 * @property {String} [textAlign=left] left, center, right
 */

/**
 * Anchor data object for format 'image'
 *
 * Displays an image at the anchor location
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#AnchorDataFormatImage
 * @property {String} src - source URL of image
 * @property {Boolean} [hidden=true] if it can be hidden by other objects
 * @property {String} [positioning.horizontal=center] left, middle, right
 * @property {String} [positioning.vertical=center] bottom, middle, top
 * @property {Number|String} height image height be given in px, or percentage of current div (will not be set if undefined)
 * @property {Number|String} width image width in px, or percentage of current div (will not be set if undefined)
 * @property {String} alt alternative text, if image display fails
 */

/**
  * 2d text tag definition - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is TAG2D.
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#Tag2d
  * @property {String} version - Always '1.0'
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color - [r,g,b,a] array of numbers between 0-255
  * @property {String} text - The text of the 2d tag
  * @property {Object} location - The point defining the location of the 2d tag
  * @property {Number} location.X
  * @property {Number} location.Y
  * @property {Number} location.Z
  */

/**
  * 3d text tag definition - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is TAG3D.
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#Tag3d
  * @property {String} version - Always '1.0'
  * @property {String} justification - One of ['BL','BC','BR','ML','MC','MR','TL','TC','TR'], meaning Bottom/Middle/Top for vertical justification
  *     and Left/Center/Right for horizontal justification within the text tags coordinate system
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color - [r,g,b,a] array of numbers between 0-255, or other valid color definition
  * @property {Number} size - Text size in model units
  * @property {String} text - The text for the tag
  * @property {Object} location - The plane (the coordinate system) defining the 3d tag's position and orientation in the scene
  * @property {Object} location.origin - Origin of the plane
  * @property {Number} location.origin.X
  * @property {Number} location.origin.Y
  * @property {Number} location.origin.Z
  * @property {Object} location.normal - Z-axis of the plane, perpendicular to the X-axis and the Y-axis
  * @property {Number} location.normal.X
  * @property {Number} location.normal.Y
  * @property {Number} location.normal.Z
  * @property {Object} location.xAxis - X-axis of the plane, aligned left to right with the text
  * @property {Number} location.xAxis.X
  * @property {Number} location.xAxis.Y
  * @property {Number} location.xAxis.Z
  * @property {Object} location.yAxis - Y-axis of the plane, aligned bottom to top with the text
  * @property {Number} location.yAxis.X
  * @property {Number} location.yAxis.Y
  * @property {Number} location.yAxis.Z
  *
  */

/**
  * OBJ reference - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is OBJ.
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#ObjMeshReference
  * @property {String} objUrl A string containing the path/URL of the .obj file.
  * @property {String} [mtlUrl] A string containing the path/URL of the .mtl file. If a material asset is assigned, too, the mtlUrl is still prioritized. Therefore, remove the mtlUrl to switch to the material asset.
  * @property {String} [path] Set base path for resolving references. If set this path will be prepended to each loaded and found reference.
  * @property {String} [texturePath] If set this path will be prepended found texture reference. If not set and setPath is, it will be used as texture base path.
  * @property {String} [side='double'] - The sidedness to use for the mesh, one of 'front', 'back', 'double'
  */

/**
  * THREE geometry reference - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is THREE.
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#ThreeGeometryReference
  * @property {THREE.Mesh|THREE.Line|THREE.Points} threeObject - A THREE object, {@link https://threejs.org/docs/#api/objects/Mesh Mesh}, {@link https://threejs.org/docs/#api/objects/Line Line}, or {@link https://threejs.org/docs/#api/objects/Points Points} are supported
  */

/**
  * Transformation matrix - Array containing the 16 elements of a 4x4 matrix in row-major format, or a {@link https://threejs.org/docs/#api/math/Matrix4 THREE.Matrix4}.
  * @typedef {Number[]|THREE.Matrix4} module:ApiSceneInterface~ApiSceneInterface#Transformation
  */

/**
 * Material type definition, see {@link module:ApiSceneInterface~ApiSceneInterface#MATERIALTYPE} for values
 * @typedef {String} module:ApiSceneInterface~ApiSceneInterface#MaterialType
 */

/**
 * Material version 1.0 type definition - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is MATERIAL.
 *
 * Supported MIME types for images: image/jpeg, image/png, image/gif, image/bmp, image/svg+xml. There are some limitations on using SVG images, preferably render the SVG into a canvas yourself and specify the canvas instead (this is only available in the {@link module:ApiSceneInterface~ApiSceneInterface#Texture texture type definition} used by {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV3})
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#MaterialV1
 * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV2}
 * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV3}
 * @see {@link module:ApiSceneInterface~ApiSceneInterface#Texture}
 * @property {String} version - must be set to "1.0"
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [ambient] - Ambient color. CAUTION: This property is ignored, it has been superseded.
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} diffuse - Main material color
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [emission] - Emissive (light) color of the material, essentially a solid color unaffected by other lighting. Default is black. CAUTION: This property is ignored, it has been superseded.
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [specular] - Specular color of the material. Default is a Color set to 0x111111 (very dark grey). CAUTION: This property is ignored, it has been superseded by environment maps.
 * @property {Number} [shine] - How shiny the specular highlight is; a higher value gives a sharper highlight. Default is 30.
 * @property {Number} [transparency] - Float in the range of 0.0 - 1.0 indicating how transparent the material is. A value of 0.0 indicates fully opaque, 1.0 is fully transparent. If transparencytexture is also provided, both values are multiplied.
 * @property {String} [bitmaptexture] - URL to the color map, can be a data URI. Default is null. The texture map color is modulated by the main material color.
 * @property {String} [bumptexture] - URL to the bump map. Can be a data URI. The black and white values map to the perceived depth in relation to the lights. Bump doesn't actually affect the geometry of the object, only the lighting.
 * @property {String} [transparencytexture] - Url to an image which will be used as a transparency texture. Can be a data URI.
 */


/**
  * Line material description
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#LineMaterial
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV2}
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV3}
  * @property {String} [type='basic'] the type of line, can be 'basic' or 'dashed' (dashed line material currently not supported for indexed line or line segment geometry)
  * @property {Number} [lineWidth=1] the line width (not well supported)
  * @property {Number} [dashSize=3] the dash size for lines of type 'dashed'
  * @property {Number} [gapSize=1] the gap size for lines of type 'dashed'
  * @property {Number} [scale=1] the scale for lines of type 'dashed'
  */

/**
  * Material version 2.0 type definition - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is MATERIAL.
  *
  * Supported MIME types for images: image/jpeg, image/png, image/gif, image/bmp, image/svg+xml. There are some limitations on using SVG images, preferably render the SVG into a canvas yourself and specify the canvas instead (this is only available in the {@link module:ApiSceneInterface~ApiSceneInterface#Texture texture type definition} used by {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV3}).
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#MaterialV2
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV1}
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV3}
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#Texture}
  * @property {String} version - must be set to "2.0"
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color - Main material color 
  * @property {String} [side] - Which side of the object to display. Options: 'front' - the front side, 'back' - the back side, '' - both sides; Default: both sides
  * @property {Number} [metalness] - How much the material is like a metal. Non-metallic materials such as wood or stone use 0.0, metallic use 1.0, with nothing (usually) in between. Default is 0.5. A value between 0.0 and 1.0 could be used for a rusty metal look. If metalnesstexture is also provided, both values are multiplied.
  * @property {Number} [roughness] - How rough the material appears. 0.0 means a smooth mirror reflection, 1.0 means fully diffuse. Default is 0.5. If roughnesstexture is also provided, both values are multiplied.
  * @property {Number} [transparency] - Float in the range of 0.0 - 1.0 indicating how transparent the material is. A value of 0.0 indicates fully opaque, 1.0 is fully transparent. If transparencytexture is also provided, both values are multiplied.
  * @property {Number} [alphaThreshold] - Sets the alpha value to be used when running an alpha test. The material will not be renderered if the opacity is lower than this value. Default is 0.
  * @property {String} [bitmaptexture] - URL to the color map, can be a data URI. Default is null. The texture map color is modulated by the material color.
  * @property {String} [metalnesstexture] - URL to the metalness map, can be a data URI. The blue channel of this texture is used to alter the metalness of the material.
  * @property {String} [roughnesstexture] - URL to the roughness map, can be a data URI. The green channel of this texture is used to alter the roughness of the material.
  * @property {String} [bumptexture] - URL to the bump map. Can be a data URI. The black and white values map to the perceived depth in relation to the lights. Bump doesn't actually affect the geometry of the object, only the lighting. If a normal map is defined this will be ignored.
  * @property {String} [normaltexture] - URL to the normal map. Can be a data URI. The RGB values affect the surface normal for each pixel fragment and change the way the color is lit. Normal maps do not change the actual shape of the surface, only the lighting.
  * @property {String} [transparencytexture] - URL to the alpha map, which is a grayscale texture that controls the opacity across the surface (black: fully transparent; white: fully opaque). Default is null.
  * @property {module:ApiSceneInterface~ApiSceneInterface#LineMaterial} [line] the definition for a line material
  */

/**
 * Texture type definition
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#Texture
 * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV3}
 * @property {String} [href] - Link to the bitmap to be used as texture, can be a data URI. Either href or canvas must be specified. Supported MIME types: image/jpeg, image/png, image/gif, image/bmp, image/svg+xml. There are some limitations on using SVG images, preferably render the SVG into a canvas yourself and specify the canvas instead.
 * @property {Object} [canvas] - Canvas to be used as texture. Either href or canvas must be specified.
 * @property {Number[]} [offset] - Offset used on uv coordinates for this texture, in each direction U and V. CAUTION: This updates the texture coordinates, be aware of this when using several textures for one mesh.
 * @property {Number[]} [repeat] - How many times the texture is repeated across the surface, in each direction U and V. CAUTION: This updates the texture coordinates, be aware of this when using several textures for one mesh.
 * @property {Number} [rotation] - How much the texture is rotated around the center point, in radians. Positive values are counter-clockwise. Default is 0. CAUTION: This updates the texture coordinates, be aware of this when using several textures for one mesh.
 * @property {Number[]} [center] - The point around which rotation occurs. A value of (0.5, 0.5) corresponds to the center of the texture. Default is (0, 0), the lower left. CAUTION: This updates the texture coordinates, be aware of this when using several textures for one mesh.
 * @property {Number} [wrapS] - wrapS Wrapping Mode, corresponds to the U in UV mapping therefore the horizontal mapping mode. Possible values: 0 (RepeatWrapping, default), 1 (ClampToEdgeWrapping), 2 (MirroredRepeatWrapping)
 * @property {Number} [wrapT] - wrapT Wrapping Mode, corresponds to the V in UV mapping therefore the vertical mapping mode. Possible values: 0 (RepeatWrapping, default), 1 (ClampToEdgeWrapping), 2 (MirroredRepeatWrapping)
 */

/**
  * 3D Noise description
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#ThreeDNoise
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV3}
  * @property {Number} [opacity] - The opacity of this effect, where a value of 0 disables this effect (default: 0.0)
  * @property {Number} [noiseID] - The ID for the noise function that is used (range: 0-3, default: 0)
  * @property {Number} [scale] - The scale of the noise (default: 1.0)
  * @property {Number} [distance] - The amount the noise fades with distance (default: 0.0)
  */

/**
  * Material version 3.0 type definition - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is MATERIAL.
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#MaterialV3
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV1}
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV2}
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#Texture}
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#ThreeDNoise}
  * @property {String} version - must be set to "3.0"
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} color - Material color
  * @property {String} [side] - Which side of the object to display. Options: 'front' - the front side, 'back' - the back side, '' - both sides; Default: both sides
  * @property {Number} [metalness] - How much the material is like a metal. Non-metallic materials such as wood or stone use 0.0, metallic use 1.0, with nothing (usually) in between. Default is 0.5. A value between 0.0 and 1.0 could be used for a rusty metal look. If metalnesstexture is also provided, both values are multiplied.
  * @property {Number} [roughness] - How rough the material appears. 0.0 means a smooth mirror reflection, 1.0 means fully diffuse. Default is 0.5. If roughnesstexture is also provided, both values are multiplied.
  * @property {Number} [transparency] - Float in the range of 0.0 - 1.0 indicating how transparent the material is. A value of 0.0 indicates fully opaque, 1.0 is fully transparent. If transparencytexture is also provided, both values are multiplied.
  * @property {Number} [alphaThreshold] - Sets the alpha value to be used when running an alpha test. The material will not be renderered if the opacity is lower than this value. Default is 0.
  * @property {Number} [shadowOpacity] - The adjustement for the opacity of the shadow. Default is 1.
  * @property {Number} [lightReflectivity] - The amount the light is reflected in metal-like surfaces. Default is 1.
  * @property {module:ApiSceneInterface~ApiSceneInterface#ThreeDNoise} [threeDNoise] - The options for the 3D Noise effect that can optionally be turned on using its opacity.
  * @property {module:ApiSceneInterface~ApiSceneInterface#Texture} [bitmaptexture] - Color texture definition. The texture map color is modulated by the material color.
  * @property {module:ApiSceneInterface~ApiSceneInterface#Texture} [metalnesstexture] - Metalness texture definition. The blue channel of this texture is used to alter the metalness of the material.
  * @property {module:ApiSceneInterface~ApiSceneInterface#Texture} [roughnesstexture] - Roughness texture definition. The green channel of this texture is used to alter the roughness of the material.
  * @property {module:ApiSceneInterface~ApiSceneInterface#Texture} [bumptexture] - Bump texture definition. The black and white values map to the perceived depth in relation to the lights. Bump doesn't actually affect the geometry of the object, only the lighting. If a normal map is defined this will be ignored.
  * @property {module:ApiSceneInterface~ApiSceneInterface#Texture} [normaltexture] - Normal texture definition. The RGB values affect the surface normal for each pixel fragment and change the way the color is lit. Normal maps do not change the actual shape of the surface, only the lighting.
  * @property {module:ApiSceneInterface~ApiSceneInterface#Texture} [transparencytexture] - Transparency texture definition. They greyscale level of this texture controls the opacity across the surface (black: fully transparent; white: fully opaque).
  * @property {module:ApiSceneInterface~ApiSceneInterface#LineMaterial} [line] the definition for a line material
  */


/**
 * Cube Texture type definition
 * Panorama maps can be converted to 6 cube maps via {@link https://viewer.shapediver.com/panorama-to-cubemap/index.html this tool}.
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#CubeTexture
 * @see {@link module:ApiSceneInterface~ApiSceneInterface#GemMaterialV1}
 * @property {String|String[]} [href] - Link to the path of the environment map to use (map names = 'px.jpg', 'nx.jpg', 'pz.jpg', 'nz.jpg', 'py.jpg', 'ny.jpg'), or an array of 6 image URLs making up the cube mapped environment map.
 * @//property {String} [name] - Name of the environment map, if it is one of the default maps.
 * @//property {Number} [resolution] - Image resolution to be used for the named environment maps, if it is one of the default maps (available resolutions: '256', '512', '1024', '2048').
 */

/**
  * Gem Material version 1.0 type definition - used to specify the data of a {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} in case its {@link module:ApiSceneInterface~ApiSceneInterface#FORMAT format} is MATERIAL.
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#GemMaterialV1
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#CubeTexture}
  * @property {String} version - must be set to "1.0"
  * @property {module:ApiSceneInterface~ApiSceneInterface#MaterialType} materialType - must be set to {@link module:ApiSceneInterface~ApiSceneInterface#MATERIALTYPE MaterialType.GEM}
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [colorTransferBegin='white'] - The main color of the gem.
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [colorTransferEnd='white'] - An additional color to add tone to the gem in the depth of the tracing.
  * @property {Number} [depth=8] - The depth of the pseudo-raytracing. This value defines how many steps are taken in the raytracing process. A higher value decreases the performance, but increases the details in the stone. [min: 0, max: 20, default: 8]
  * @property {Number} [transparency=0] - The overall transparency of the gem. Affects the complete object uniformly. [min: 0, max: 1, default: 1]
  * @property {Number} [tracingTransparency=0] - As the amount of transparency can vary on a per face basis in the tracing calculations, this value serves as a scaling factor for this transparency. [min: 0, max: 1, default: 1]
  * @property {Number} [refractionIndex=2.4] - The refraction index of the object. There are various resources online to find the right refraction indices for different types of stones like {@link https://www.gemsociety.org/article/table-refractive-index-double-refraction-gems/ here}. [min: 0, max: 4, default: 2.4]
  * @property {Number} [dispersion=0] - The amount of dispersion. The larger this value is, the more areas are affected. If the dispersion is set to 0, no dispersion is calculated (improves the performance). [min: 0, max: 1, default: 0]
  * @property {module:ApiSceneInterface~ApiSceneInterface#CubeTexture} [environmenttexture=''] - The environment map for this material. If non is applied, the environment map of the scene is used.
  * @property {module:ApiSceneInterface~ApiSceneInterface#CubeTexture} [impuritytexture=''] - The impurity map of this material. This map affects the probability of the ray progressing through the stone. Only the red color channel is used.
  * @property {Number} [impurityScale=1.0] - The impurity scale affects the application of the impurity map to the probability. [min: 0, max: 1, default: 1]
  * @property {Number} [gamma=1.0] - The gamma value affects the individual results of each tracing step. [min: 0, max: 10, default: 1]
  * @property {Number} [contrast=1.0] - The contrast value affects the individual results of each tracing step. [min: 0, max: 10, default: 1]
  * @property {Number} [brightness=0.0] - The brightness value affects the individual results of each tracing step. [min: -1, max: 1, default: 0]
  */


/**
 * Scene asset item - each {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset} can have multiple items
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem
 * @see {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset}
 * @property {module:ApiSceneInterface~ApiSceneInterface#SceneAssetItemFormat} format - one of the supported scene asset item formats
 * @property {String} [href] - Link to the geometry data. Either this property or the 'data' property must be provided.
 * @property {Array<module:ApiSceneInterface~ApiSceneInterface#Anchor>|Array<module:ApiSceneInterface~ApiSceneInterface#Tag2d>|Array<module:ApiSceneInterface~ApiSceneInterface#Tag3d>|module:ApiSceneInterface~ApiSceneInterface#MaterialV1|module:ApiSceneInterface~ApiSceneInterface#MaterialV2|module:ApiSceneInterface~ApiSceneInterface#MaterialV3|module:ApiSceneInterface~ApiSceneInterface#GemMaterialV1|module:ApiSceneInterface~ApiSceneInterface#ObjMeshReference|module:ApiSceneInterface~ApiSceneInterface#ThreeGeometryReference|*} [data] - Data as defined by the 'format' property.
 * @property {module:ApiSceneInterface~ApiSceneInterface#Transformation[]} [transformations] - Optional property defining the transformation matrices to be used for displaying instances of this output. If this property exists it must contain at least one transformation. CAUTION: These transformations are only applied to asset items whose format is GLB, OBJ, and THREE.
 */

/**
 * Interaction mode type definition, used to define whether a complete {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset asset} or its subobjects should become selectable, hoverable, or draggable, see {@link module:ApiSceneInterface~ApiSceneInterface#INTERACTIONMODETYPE} for values
 * @typedef {String} module:ApiSceneInterface~ApiSceneInterface#InteractionModeType
 */

/**
  * Scene asset
  *
  * This definition describes an asset containing geometry and/or material items.
  *
  * If an asset contains no material items, it may instead link to another asset which does.
  * This makes it possible to replace geometry and materials separately - if a
  * material is replaced, that change is reflected in all the geometry objects which
  * link to it.
  *
  * All properties can be optional depending on the context.
  *
  * If the API returns an asset, it usually includes all properties.
  *
  * For geometry updates using {@link module:ApiSceneInterface~ApiSceneInterface#updateAsync updateAsync} one can send only the properties which need to be changed,
  * along with the id of the object to be changed.
  *
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#SceneAsset
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#updateAsync updateAsync}
  * @property {String} id - The unique id of this asset. Ids of existing assets can be retrieved using {@link module:ApiSceneInterface~ApiSceneInterface#get get}. This id should be remembered to reference the asset later, e.g. to update or remove it.
  * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [scenePath] - scene path of the asset (composition of the runtime id of the owner of the asset, and the unique id of the asset)
  * @property {String} [name] - The human readable name of the asset
  * @property {String} [group] - If provided, defines a logical group which this asset belongs to. The group can be used as a filter in calls to {@link module:ApiSceneInterface~ApiSceneInterface#get get}.
  * @property {String} [material] - Id of the material asset to be used for the geometry defined by this asset. Can be ommited if this asset contains its own materials (see property `content`), or if the geometry in this asset has embedded materials. Takes precedence over materials defined in `content`.
  * @property {String} [version] - Unique version id of this asset. If the same asset is provided again later with the same version id, caching might improve performance. A random version id will be created if none is specified.
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray} [bbmin] - Minimum coordinates of the axis-aligned bounding box of the geometry in this asset. If not provided, this will be computed. Not used for material assets. Set this to null when updating geometry and you don't know the updated bounding box, which will cause the bounding box to be recomputed in the viewer. If you don't do this, the previous bounding box information will persist.
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray} [bbmax] - Maximum coordinates of the axis-aligned bounding box of the geometry in this asset. If not provided, this will be computed. Not used for material assets. Set this to null when updating geometry and you don't know the updated bounding box, which will cause the bounding box to be recomputed in the viewer. If you don't do this, the previous bounding box information will persist.
  * @property {module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem[]} content - Items of this asset - the geometries and materials to be added to the scene. Materials
  * @property {String} format - In case all items of the content array share the same {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItemFormat format}, this will be reflected here.
  *                    In case several formats are part of the content array, this will be set to `mixed`.
  *                    In case the content array is empty, this will be set to `unknown`.
  * @property {Number} [duration] - Duration of transition in msec for fading in and out, defaults to 500 for replacements and 0 for addition and removal.
  * @property {String} [interactionGroup] - Specifies the id of the {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} to which this asset belongs.
  * @property {Number|module:ApiSceneInterface~ApiSceneInterface#InteractionModeType} [interactionMode=module:ApiSceneInterface~ApiSceneInterface#INTERACTIONMODETYPE.SUB] - Allows to specify whether the complete asset, an individual level or its individual subobjects are selectable/hoverable/draggable. Provide a number to specify at which level of the scene path the interaction shall happen (0 - SceneAsset, 1 - SceneAssetItem, 2 - transformations of SceneAssetItem, etc). Requires an {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} to be specified. See {@link module:ApiSceneInterface~ApiSceneInterface#INTERACTIONMODETYPE} for values.
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d|module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray|module:ApiInterfaceV2~ApiInterfaceV2#Point3d[]|module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray[]} [dragPlaneNormal] - Optional normal to the plane which should be used for dragging. If not specified, dragging will happen in a plane normal to the viewing direction. Requires an {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} to be specified. The dragPlane is prioritized over the dragPlaneNormal.
  * @property {Object|Object[]} [dragPlane] - Optional normal to the plane and distance to the origin which should be used for dragging. If not specified, dragging will happen in a plane normal to the viewing direction. Requires an {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} to be specified. The dragPlane is prioritized over the dragPlaneNormal.
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d|module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray} [dragOffset] - Optional offset which is used while the object is being dragged. Per default the intersection point at the start of the interaction is used. Requires an {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} to be specified.
  * @property {Function} [addEventListener] - When the API returns an asset, it adds this function to allow registering to events limited to this specific object (scene path). Refer to the documentation of the global {@link module:ApiSceneInterface~ApiSceneInterface#addEventListener addEventListener} function.
  * #SS-928 allow to specify a global transformation for the complete asset here
  */

/**
  * Scene asset filter - used to define filter criteria for retrieving information about assets in the scene
  *
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#SceneAssetFilter
  * @property {String} [id] - Unique id of the asset.
  * @property {String} [name] - Human readable name of the asset.
  * @property {String} [group] - Logical group the asset belongs to.
  * @property {String} [material] - Id of the material asset to be used for the geometry defined by this asset.
  * @property {String} [version] - Unique version id of this asset.
  * @property {Number} [duration] - Duration of transition in msec for fading in and out.
  * @property {String} [interactionGroup] - Id of the {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} to which this asset belongs.
  */

/**
  * Scene event type, see {@link module:ApiSceneInterface~ApiSceneInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiSceneInterface~ApiSceneInterface#SceneEventType
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#addEventListener addEventListener}
  */

/**
  * Scene data item - generic data attached to the scene
  * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#ModelDataItem
  * @property {String} name - name of the data item
  * @property {String} id - unique id of the data item
  * @property {String} plugin - plugin runtime id this item belongs to
  * @property {*} data - the data
  */

/**
  * Selection type, see {@link module:ApiSceneInterface~ApiSceneInterface#SELECTIONMODETYPE} for values
  * @typedef {String} module:ApiSceneInterface~ApiSceneInterface#SelectionModeType
  * @see {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup}
  */

/**
 * Interaction Effect
 *
 * Defines the effect used on the active and passive objects of an {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} during an interaction event.
 *
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#InteractionEffect
 * @property {String} [name='colorHighlight'] - The name of a predefined effect type to use. Currently 'colorHighlight' and 'opacityHighlight' are supported.
 * @property {Object} [options] - Options for the effect
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Color} [options.color='red'] Color for the 'colorHighlight' effect
 * @property {Number} [options.opacity=0.5] Opacity value between 0 and 1 for the 'opacityHighlight' effect
 */

/**
 * Interaction Group
 *
 * This type defines the interaction behaviour of a group of objects within the scene.
 * {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAssets} can be assigned to {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroups} by means of the property interactionGroup of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset}.
 *
 * For an expansion of the Interactions that allow snapping to points and lines see the [Snap Module](./snapModule/index.html).
 * 
 * @typedef {Object} module:ApiSceneInterface~ApiSceneInterface#InteractionGroup
 * @property {String} id - Id of the interaction group
 * @property {Boolean} [hoverable=false] - Will the group objects react if the user hovers over them with the mouse?
 * @property {Object} [hoverEffect] - Effects used for hovering events
 * @property {module:ApiSceneInterface~ApiSceneInterface#InteractionEffect} [hoverEffect.active] - Effect used for the hovering object
 * @property {module:ApiSceneInterface~ApiSceneInterface#InteractionEffect} [hoverEffect.passive] - Effect used for the other (non hovering) objects in the group
 * @property {Boolean} [selectable=false] - Will the group objects be selectable?
 * @property {Object} [selectionEffect] - Effect used for selection events
 * @property {module:ApiSceneInterface~ApiSceneInterface#InteractionEffect} [selectionEffect.active] - Effect used for selected objects
 * @property {module:ApiSceneInterface~ApiSceneInterface#InteractionEffect} [selectionEffect.passive] - Effect used for non-selected objects
 * @property {module:ApiSceneInterface~ApiSceneInterface#SelectionModeType} [selectionMode='single'] - Allows to specify whether single or multiple objects belonging to the group are selectable, see {@link module:ApiSceneInterface~ApiSceneInterface#SELECTIONMODETYPE} for values
 * @property {Boolean} [draggable=false] - Will the group objects be draggable?
 * @property {Object} [dragEffect] - Effect used for dragging events
 * @property {module:ApiSceneInterface~ApiSceneInterface#InteractionEffect} [dragEffect.active] - Effect used for dragged objects
 * @property {module:ApiSceneInterface~ApiSceneInterface#InteractionEffect} [dragEffect.passive] - Effect used for not currently dragged objects
 */

/**
 * @typedef module:ApiSceneInterface~ApiSceneInterface#2DCoordinateSummary
 * @property {Number} [containerX] - horizontal coordinate (relative to the top-left corner of the viewer container) of the 3D position
 * @property {Number} [containerY] - vertical coordinate (relative to the top-left corner of the viewer container) of the 3D position
 * @property {Number} [clientX] - horizontal coordinate (relative to the top-left corner of the viewport) of the 3D position
 * @property {Number} [clientY] - vertical coordinate (relative to the top-left corner of the viewport) of the 3D position
 * @property {Number} [pageX] - horizontal coordinate (relative to the top-left corner of the document) of the 3D position
 * @property {Number} [pageY] - vertical coordinate (relative to the top-left corner of the document) of the 3D position
 */


/**
 * @typedef module:ApiSceneInterface~ApiSceneInterface#BoundingBox
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray} [min] - Minimum coordinates of the axis-aligned bounding box.
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3dArray} [max] - Maximum coordinates of the axis-aligned bounding box.
 */

/**
  * Transformation type, see {@link module:ApiSceneInterface~ApiSceneInterface#TRANSFORMATIONTYPE} for values
  * @typedef {String} module:ApiSceneInterface~ApiSceneInterface#TransformationType
  */

/**
 * @typedef LiveTransformation
 * @property {module:ApiSceneInterface~ApiSceneInterface#LiveTransformationType} type the type of the transformation
 * @property {Number} duration the duration of the transformation (when a delay is given, the delay + the duration are combined for the overall duration)
 * @property {Number} [delay=0] the milliseconds after which the transformation will start
 * @property {Number} [repeat=0] the number of times the live transformation should be repeated after the initial run
 * @property {Boolean} [yoyo=false] if a repeat value is defined, the yoyo value defines if the alternating transformation should be performed in inverse order
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [parent] the scene path to the parent of this object, the transformation that is applied on the parent is also applied to this object
 * @property {String|Function} [easing='Linear.None'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [pivot] a pivot as a 3D position that can be used for rotations and scaling
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [rotationAxis] the rotation axis (necessary for {@link module:ApiSceneInterface~ApiSceneInterface#LIVETRANSFORMATIONTYPE} rotation)
 * @property {Number} [rotationDegree] the degree of rotation (necessary for {@link module:ApiSceneInterface~ApiSceneInterface#LIVETRANSFORMATIONTYPE} rotation)
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [scalingVector] the scaling vector (necessary for {@link module:ApiSceneInterface~ApiSceneInterface#LIVETRANSFORMATIONTYPE} scaling)
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} [translationVector] the translation vector (necessary for {@link module:ApiSceneInterface~ApiSceneInterface#LIVETRANSFORMATIONTYPE} translation)
 */

/**
 * @typedef LiveTransformationObject
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} scenePaths an array of scene paths to which to apply the transformations
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#LiveTransformation[]} transformations the individual transformations that are applied in the order provided
 * @property {Boolean} [reset=true] determines if all objects with the provided paths should be reset to their previous position at the end of the transformations
 */

/**
 * @typedef LiveTransformationSingleResponse
 * @property {Function} stop a functions that stops the live transformation
 * @property {Promise} promise a promise that resolves when the live transformation has finished
 */

/**
 * @typedef LiveTransformationResponse
 * @property {module:ApiInterfaceV2~ApiInterfaceV2#LiveTransformationSingleResponse[]} responses an array of {@link module:ApiInterfaceV2~ApiInterfaceV2#LiveTransformationSingleResponse} for the inidividual live transformations
 * @property {Function} stop a functions that stops all live transformations that were created with the original call
 * @property {Promise} promise a promise that resolves when all the live transformations have finished
 */

/**
  * Live Transformation type, see {@link module:ApiSceneInterface~ApiSceneInterface#LIVETRANSFORMATIONTYPE} for values
  * @typedef {String} module:ApiSceneInterface~ApiSceneInterface#LiveTransformationType
  */


/**
 * ### ShapeDiver Viewer - Scene API
 *
 * The scene interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
 *
 *  * provides access to the {@link module:ApiSceneInterface~ApiSceneInterface#camera camera} and {@link module:ApiSceneInterface~ApiSceneInterface#lights lights} of the scene
 *  * allows to add, update, and remove assets in the scene
 *  * allows to define the interaction behavior of the geometry in the scene (hovering, selection, dragging)
 *  * allows to add and remove handlers for related events
 *
 * @interface ApiSceneInterface
 */
var ApiSceneInterface = function () {

  /**
   * Enum for supported scene asset item formats.
   * @readonly
   * @enum {module:ApiSceneInterface~ApiSceneInterface#SceneAssetItemFormat}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem}
   */
  this.FORMAT = {
    /** property href of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} points to a glb file ({@link https://github.com/KhronosGroup/glTF/tree/master/extensions/1.0/Khronos/KHR_binary_glTF binary glTF 1.0}) */
    GLB: 'glb',
    /** property href of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} points to a {@link https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md glTF 2.0 file}.
    Binary glTF is supported. For now there is no support for gltf extensions, animations, multiple uv coords, and emissive textures. */
    GLTF : 'gltf',
    /** property data of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains an {@link module:ApiSceneInterface~ApiSceneInterface#ObjMeshReference ObjMeshReference} which points to a {@link https://en.wikipedia.org/wiki/Wavefront_.obj_file Wavefront OBJ file} (supports meshes only, no lines, curves, or surfaces) */
    OBJ: 'obj',
    /** property data of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem}
     * contains a {@link module:ApiSceneInterface~ApiSceneInterface#ThreeGeometryReference ThreeGeometryReference} which allows to directly add
     * {@link https://threejs.org/docs/#api/objects/Mesh Mesh},
     * {@link https://threejs.org/docs/#api/objects/Line Line}, {@link https://threejs.org/docs/#api/objects/LineLoop LineLoop}, {@link https://threejs.org/docs/#api/objects/LineSegments LineSegments}, or
     * {@link https://threejs.org/docs/#api/objects/Points Points} objects to the scene.
     */
    THREE: 'three',
    /** property data of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains generic data (will not be displayed in the scene, but can be requested using {@link module:ApiSceneInterface~ApiSceneInterface#getData}) */
    DATA: 'data',
    /** property data of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains a {@link module:ApiSceneInterface~ApiSceneInterface#MaterialV3 standard material definition} or {@link module:ApiSceneInterface~ApiSceneInterface#GemMaterialV1 gem material definition} */
    MATERIAL: 'material',
    /** property data of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains an array of {@link module:ApiSceneInterface~ApiSceneInterface#Tag2d Tag2d} definitions */
    TAG2D: 'tag2d',
    /** property data of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains an array of {@link module:ApiSceneInterface~ApiSceneInterface#Tag3d Tag3d} definitions */
    TAG3D: 'tag3d',
    /** property data of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAssetItem SceneAssetItem} contains an array of {@link module:ApiSceneInterface~ApiSceneInterface#Anchor anchor} definitions */
    ANCHOR: 'anchor'
  };

  /**
   * Enum for supported scene event types.
   * @readonly
   * @enum {module:ApiSceneInterface~ApiSceneInterface#SceneEventType}
   */
  this.EVENTTYPE = {
    /**  anchor objects have been added to the scene */
    ANCHOR_ADD: 'anchor.add',
    /**  anchor objects have been removed from the scene */
    ANCHOR_REMOVE: 'anchor.remove',
    /** dragging of an object started */
    DRAG_START: 'drag.start',
    /** continuously fired during dragging of an object */
    DRAG_MOVE: 'drag.move',
    /** dragging of an object ended */
    DRAG_END: 'drag.end',
    /** framerate events every second (if rendering)  */
    FRAMERATE: 'framerate',
    /** pointing device has entered a hoverable object  */
    HOVER_ON: 'hover.on',
    /** continuously fired during hovering of an object  */
    HOVER_OVER: 'hover.over',
    /** pointing device has left a hoverable object */
    HOVER_OFF: 'hover.off',
    /** beauty rendering has started */
    RENDER_BEAUTY_START: 'render.beauty.start',
    /** beauty rendering has been cancelled */
    RENDER_BEAUTY_CANCEL: 'render.beauty.cancel',
    /** beauty rendering has ended */
    RENDER_BEAUTY_END: 'render.beauty.end',
    /** object has been selected */
    SELECT_ON: 'select.on',
    /** object has been unselected */
    SELECT_OFF: 'select.off',
    /** A subscene, including data it may contain, has become visible in the viewer after a parameter update or the initial scene loading.
     * This event is fired before fading in of the new subscene has been finished.
     */
    SUBSCENE_PUBLISHED: 'subscene.published',
    /** Scene has become visible. This event is fired whenever the `scene.show` setting is changed from `false` to `true`. */
    VISIBILITY_ON: 'visibility.on',
    /** Scene has become invisible. This event is fired whenever the `scene.show` setting is changed from `true` to `false`. */
    VISIBILITY_OFF: 'visibility.off',
  };

  /**
   * Enum for supported material types.
   * @readonly
   * @enum {module:ApiSceneInterface~ApiSceneInterface#MaterialType}
   */
  this.MATERIALTYPE = {
    /** identifier for the standard material */
    STANDARD: 'standard',
    /** identifier for the gem material */
    GEM: 'gem',
  };

  /**
   * Enum for supported interaction modes. Allows to specify whether a complete {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset asset} or its individual subobjects should become selectable/hoverable/draggable.
   * This enum is used for property interactionMode of the {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset}
   * @readonly
   * @enum {module:ApiSceneInterface~ApiSceneInterface#InteractionModeType}
   */
  this.INTERACTIONMODETYPE = {
    /** object and subobjects are globally selectable/hoverable/draggable, i.e. all of them at the same time */
    GLOBAL: 'global',
    /** object and subobjects are individually selectable/hoverable/draggable */
    SUB: 'sub',
  };

  /**
   * Enum for supported selection modes. Allows to specify whether a single or multiple objects within an {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} are selectable.
   * @readonly
   * @enum {module:ApiSceneInterface~ApiSceneInterface#SelectionModeType}
   */
  this.SELECTIONMODETYPE = {
    /** A single object of the {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} can be selected, all objects of the group are deselected on a click which does not hit an object of the group. */
    SINGLE: 'single',
    /** Selection status of objects belonging to the {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup InteractionGroup} is toggled by clicking on them, selection status does not change on a click which does not hit an object of the group. */
    MULTIPLE: 'multiple',
  };

  /**
   * Enum for supported transformation types. Allows to specify whether a transformation matrix should be applied to the geometry of a plugin or the geometry within a viewport.
   * @readonly
   * @enum {module:ApiSceneInterface~ApiSceneInterface#TransformationType}
   */
  this.TRANSFORMATIONTYPE = {
    /** the transformation is applied to the geometry of a plugin */
    PLUGIN: 'plugin',
    /** the transformation is applied to the geometry in a specific viewport */
    VIEWPORT: 'viewport'
  };

  /**
   * Enum for supported live transformation types.
   * @readonly
   * @enum {module:ApiSceneInterface~ApiSceneInterface#LiveTransformationType}
   */
  this.LIVETRANSFORMATIONTYPE = {
    /** the transformation is a rotation */
    ROTATION: 'rotation',
    /** the transformation is a translation */
    TRANSLATION: 'translation',
    /** the transformation is a scaling */
    SCALING: 'scaling',
  };

  /**
   * Add and update assets (typically geometry) in the scene.
   *
   * By default, the assets added through instances of this API live in their own
   * namespace within the scene (one namespace per API instance, defined by its {@link module:ApiInterfaceV2~ApiInterfaceV2#getRuntimeId runtime id}). To access and update the assets
   * provided by a plugin, please specify the plugin runtime id as namespace.
   *
   * Note: Scene updates take place asynchronously. For each runtime id only one scene update can be active simultaneously. Should a scene update be
   * triggered while a previous one is still going on, the previous scene update will get superseded and not included in the scene.
   *
   * @param {module:ApiSceneInterface~ApiSceneInterface#SceneAsset[]} assets - The assets which should be added to or updated in the scene. Missing information will default to the corresponding data currently in the scene.
   * @param {String} [namespace] - By default assets belonging to the namespace of the instance of this API will be updated/added. If the asset to be updated/added is owned by a plugin, specify its runtime id here.
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @param {Object} [options] - Addition options for this update call
   * @param {boolean} [options.busy=false] - Wether the viewer should go into busy mode and blur the scene while the scene is updated
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset}. Contains a full description of the added/updated assets as shown in the scene after the update, persistent attributes applied.
   */
  this.updateAsync = function () { };

  /**
   * Retrieve assets from the scene
   *
   * Returns scene assets corresponding to given filter criteria.
   * Depending on the value of blnScene the returned assets describe their original state, or their original state with persistent attributes applied.
   *
   * @param {module:ApiSceneInterface~ApiSceneInterface#SceneAssetFilter} [filter] - Optional partial asset description whose properties are used as filter criteria. Most commonly, the id or name will be defined.
   * @param {String} [namespace] - By default the assets belonging to the namespace of the instance of this API will be returned. If you want to request assets owned by a plugin, specify its runtime id here.
   * @param {Boolean} [blnScene=false] - If true use assets with persistent attributes applied, if false (default) use the original assets without persistent attributes applied.
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset}. Contains a full description of the assets fulfilling the filter criteria. Returned data array may be empty.
   */
  this.get = function () { };

  /**
   * Retrieves generic data assets from the scene, optionally limited according to the given filters.
   *
   * @param {module:ApiSceneInterface~ApiSceneInterface#SceneAssetFilter} [filter] - Optional partial asset description whose properties are used as filter criteria. Properties 'name' and 'id' will be used only, other properties will be ignored.
   * @param {String} [namespace] - By default all data assets in the scene will be returned. If you want to limit the request to data assets of a specific owner (e.g. this API instance), specify the owner's runtime id here.
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#ModelDataItem ModelDataItem}. Contains all data assets fulfilling the filter criteria.
   */
  this.getData = function () { };

  /**
   * Converts the given 3D coordinates into various 2D coordinates
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#2DCoordinateSummary}
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} position - The 3D point to convert
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#2DCoordinateSummary 2DCoordinateSummary} A summary of the different 2D coordinates, array of summaries in case of multiple viewports, null on error
   */
  this.convertTo2D = function () { };

  /**
   * Returns the bounding box of the visible objects at or below the provided path.
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} [path] - Scene path for which the bounding box should be returned, omit or pass empty string to return the bounding box of the complete scene
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#BoundingBox BoundingBox}.
   */
  this.getBoundingBox = function () { };

  /**
   * Sets a 4x4 transformation matrix or an array of transformation matrices for a plugin or a viewport.
   *
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#getTransformation getTransformation}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#applyTransformation applyTransformation}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#resetTransformation resetTransformation}
   * @param {module:ApiSceneInterface~ApiSceneInterface#TransformationType} type - The {@link module:ApiSceneInterface~ApiSceneInterface#TRANSFORMATIONTYPE type} for which the transformations should be set.
   * @param {String} id - runtime id of the plugin or the viewport, if a falsy value is provided, the transformations are set for all plugins or viewports (depending on the type).
   * @param {module:ApiSceneInterface~ApiSceneInterface#Transformation|module:ApiSceneInterface~ApiSceneInterface#Transformation[]} matrix - The transformation matrix or an array of transformation matrices
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type boolean.
   */
  this.setTransformation = function () { };

  /**
   * Gets the 4x4 transformation matrix or the array of matrices currently configured for a plugin or a viewport.
   *
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#setTransformation setTransformation}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#applyTransformation applyTransformation}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#resetTransformation resetTransformation}
   * @param {module:ApiSceneInterface~ApiSceneInterface#TransformationType} type - The {@link module:ApiSceneInterface~ApiSceneInterface#TRANSFORMATIONTYPE type} for which the transformations should be returned.
   * @param {String} id - runtime id of the plugin or the viewport, if a falsy value is provided, the transformations are returned for all plugins or viewports (depending on type)
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#Transformation matrix}.
   */
  this.getTransformation = function () { };

  /**
   * Applies a 4x4 transformation matrix or an array of matrices to a plugin or a viewport.
   *
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#setTransformation setTransformation}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#getTransformation getTransformation}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#resetTransformation resetTransformation}
   * @param {module:ApiSceneInterface~ApiSceneInterface#TransformationType} type - The {@link module:ApiSceneInterface~ApiSceneInterface#TRANSFORMATIONTYPE type} for which the transformations should be applied.
   * @param {String} id - runtime id of the plugin or the viewport, if a falsy value is provided, the transformations are applied to all plugins or viewports (depending on type)
   * @param {module:ApiSceneInterface~ApiSceneInterface#Transformation|module:ApiSceneInterface~ApiSceneInterface#Transformation[]} matrix - The transformation matrix or an array of transformation matrices.
   *            In case of an array the matrices are premultiplied in the given order to the existing matrix.
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type boolean.
   */
  this.applyTransformation = function () { };

  /**
   * Resets the 4x4 transformation matrix or the array of matrices for a plugin or a viewport.
   *
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#setTransformation setTransformation}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#getTransformation getTransformation}
   * @see {@link module:ApiSceneInterface~ApiSceneInterface#applyTransformation applyTransformation}
   * @param {module:ApiSceneInterface~ApiSceneInterface#TransformationType} type - The {@link module:ApiSceneInterface~ApiSceneInterface#TRANSFORMATIONTYPE type} for which the transformations should be reset.
   * @param {String} id - runtime id of the plugin or the viewport, if a falsy value is provided, the transformations will be reset for all plugins or viewports (depending on the type).
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type boolean.
   */
  this.resetTransformation = function () { };

  /**
   * Applies rotation, scaling or translation for different objects in different time frames.
   *
   * ```
   * // live transformation for 3 objects
   *
   * // object 1 (PATH_1) is translated 300 unit in the x direction in 2500 ms
   * // and the translated 300 unit in the oposite direction to its starting point (repeat 1, yoyo true)
   *
   * // object 1 also starts to rotate after 1000 ms around the z-axis and around a pivot
   * // the rotation will not finish as the overall duration of 5000 ms will be exceeded (1000 ms delay + 5000 ms duration)
   *
   * // object 1 will be reset to its previous position as reset is set to true
   *
   * // object 2 (PATH_2) and object 3 (PATH_3) inherit all transformations of object 1, as it is its parent
   * // these objects additionally perform a scaling operation
   *
   * api.scene.setLiveTransformation(
   *      [
   *        {
   *          scenePaths: ['PATH_1'],
   *          transformations: [
   *            {
   *              delay: 0,
   *              duration: 2500,
   *              type: 'translation',
   *              easing: 'Quadratic.In',
   *              translationVector: { x: 300, y: 0, z: 0 },
   *              repeat: 1,
   *              yoyo: true
   *            },
   *            {
   *              delay: 1000,
   *              duration: 5000,
   *              type: 'rotation',
   *              rotationAxis: { x: 0, y: 0, z: 1 },
   *              rotationDegree: -360,
   *              pivot: { x: 100, y: 0, z: 0 }
   *            }
   *          ],
   *          reset: true
   *        },
   *        {
   *          scenePaths: ['PATH_2', 'PATH_3'],
   *          transformations: [
   *            {
   *              delay: 0,
   *              duration: 2500,
   *              type: 'scaling',
   *              scalingVector: { x: 1, y: 1, z: 2 },
   *              parent: 'PATH_1',
   *              repeat: 1,
   *              yoyo: true
   *            },
   *          ],
   *          reset: true
   *        },
   *      ], null, 5000
   *    );
   * ```
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#LiveTransformationObject[]} liveTransformations an array of {@link module:ApiInterfaceV2~ApiInterfaceV2#LiveTransformationObject}
   * @param {String[]} [viewports] the runtime ids of the viewports to which to apply the transformations, if nothing is provided it is applied to all viewports
   * @param {Number} [duration] the maximum duration of the live transformations, the individual durations of the transformations will be ignored it they are longer
   * @returns {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with data of type {@link module:ApiInterfaceV2~ApiInterfaceV2#LiveTransformationResponse}
   */
  this.setLiveTransformation = function () { };

  /**
   * @deprecated 
   * This function is deprecated, please use getScreenshotAsync instead.
   * 
   * Get screenshot as a png data URI.
   *
   * Returns a screenshot of the viewer WebGL canvas (without any control elements) as a data URI using the png image format.
   * @return {String|String[]} Data URI representing the png screeshot image (data:image/png;base64,...). Array of data URIs in case of multiple viewports.
   */
  this.getScreenshot = function () { };

  
  /**
   * Get screenshot as a png data URI.
   *
   * Returns a screenshot of the viewer WebGL canvas (without any control elements) as a data URI using the png image format.
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type Data URI representing the png screeshot image (data:image/png;base64,...). Array of data URIs in case of multiple viewports.
   */
  this.getScreenshotAsync = function () { };



  /**
   * Update selection status of objects.
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [select] - Optional list of scene paths of objects which should be selected (please note that there might be further selected objects after this update, which had already been selected)
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [deselect] - Optional list of scene paths of objects which should be deselected
   * @return {Boolean} true if selection status could be set for all specified scene paths, false if at least one error occured.
   */
  this.updateSelected = function () { };

  /**
   * Get scene paths of selected objects.
   *
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} Array of scene paths of selected objects.
   */
  this.getSelected = function () { };

  /**
   * Start an external drag event for an object with a certain path.
   * This function is designed to provide support when something is dragged into the viewer.
   * This function is called beforehand with the path of the to-be-dragged object and listens for `mousemove` or `touchmove` events inside the viewer.
   * The to-be-dragged object should ideally be hidden (see {@link module:ApiSceneInterface~ApiSceneInterface#toggleGeometry toggleGeometry}), as it will automatically be shown as soon as the dragging starts.
   * 
   * If there is a `mouseup` or `touchend` event before a `mousemove` or `touchmove` event, this process is cancelled automatically.
   * 
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType} path - The scene path to the object that is to be dragged
   * @param {string} [eventType='mouse'] - The event type that is expected to be listened to. Either `mouse` or `touch`
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean indicating whether the path was stored for dragging.
   */
  this.startExternalDragEvent = function(path, eventType) { };

  /**
   * Remove {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset assets} from the scene according to the given filter criteria.
   *
   * @param {module:ApiSceneInterface~ApiSceneInterface#SceneAssetFilter} [filter] - Optional partial asset description whose properties are used as filter criteria. Most commonly, the id or name will be defined.
   * @param {String} [namespace] - By default the assets belonging to the namespace of the instance of this API will be removed. If you want to remove assets owned by a plugin, specify its runtime id here.
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset}. Contains a full description of the removed assets before removal, persistent attributes applied.
   */
  this.removeAsync = function () { };

  /**
   * Renders the scene
   *
   * Except in rare cases (e.g. after updating canvas textures) there is no need to call this, as rendering will be called automatically.
   */
  this.render = function () { };

  /**
   * Updates the shadow map if needed. Normally, all updates should be covered.
   */
  this.updateShadowMap = function () { };

  /**
   * Starts continously rendering the scene until {@link module:ApiSceneInterface~ApiSceneInterface#stopContinuousRendering} is called.
   */
  this.startContinuousRendering = function () { };

  /**
   * Stops continously rendering the scene if {@link module:ApiSceneInterface~ApiSceneInterface#startContinuousRendering} was called.
   */
  this.stopContinuousRendering = function () { };

  /**
   * Pauses the rendering until {@link module:ApiSceneInterface~ApiSceneInterface#resume} is called.
   */
  this.pause = function () { };

  /**
   * Resumes the rendering if it was paused by {@link module:ApiSceneInterface~ApiSceneInterface#pause}.
   */
  this.resume = function () { };

  /**
   * Define persistent attributes for scene assets which will survive and override regular updates.
   *
   * By default, the assets added through instances of this API live in their own
   * namespace within the scene (one namespace per API instance, defined by its {@link module:ApiInterfaceV2~ApiInterfaceV2#getRuntimeId runtime id}).
   * To access and update the assets provided by a plugin, please specify the plugin runtime id.
   *
   * @param {module:ApiInterfaceV2~SceneAsset[]} assets - Any information in these asset definitions will supersede information included in updates by the respective plugin.
   *       To remove a persistent attribute, set its value to null. The 'id' attribute needs to be set in any case.
   *       The 'duration' attribute will not be set persistently, but used for the actual scene update due to changing the persistent attributes.
   * @param {String} [namespace] - By default persistent attributes of assets belonging to the namespace of the instance of this API will be updated/added. If the asset to be updated/added is owned by a plugin, specify its runtime id here.
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset}. Contains a full description of the currently configured persistent attributes of the updated assets.
   */
  this.updatePersistentAsync = function () { };

  /**
   * Retrieve the persistent attributes of {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset assets}.
   *
   * @param {module:ApiSceneInterface~ApiSceneInterface#SceneAssetFilter} [filter] - Optional partial asset description whose properties are used as filter criteria. Most commonly, the id or name will be defined. Be aware that this filter is applied to the assets without persistent attributes applied.
   * @param {String} [namespace] - By default the persistent attributes of assets belonging to the namespace of the instance of this API will be returned. If you want to request persistent attributes of assets owned by a plugin, specify its runtime id here.
   * @return {module:ApiInterfaceV2~SceneAsset[]} API response with a data array of type {@link module:ApiSceneInterface~ApiSceneInterface#SceneAsset SceneAsset}. Contains persistent attributes of assets fulfilling the given filter criteria.
   */
  this.getPersistent = function () { };

  /**
   * Add or change an interaction group definition
   *
   * Interaction groups define the behaviour of their members for hovering, selection and dragging.
   *
   * @param {module:ApiSceneInterface~ApiSceneInterface#InteractionGroup|module:ApiSceneInterface~ApiSceneInterface#InteractionGroup[]} groups
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean indicating whether the interaction groups could be updated.
   */
  this.updateInteractionGroups = function () { };

  /**
   * Returns all currently defined {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup interaction groups}.
   *
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a list of {@link module:ApiSceneInterface~ApiSceneInterface#InteractionGroup[] interaction groups}
   */
  this.getInteractionGroups = function () { };

  /**
   * Add a global event listener to the scene
   *
   * @param {module:ApiSceneInterface~ApiSceneInterface#SceneEventType} type - Type of event type to subscribe to. A {@link module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType scene path} may be dot-appended to limit the subscription to parts of the scene.
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiSceneInterface~ApiSceneInterface#removeEventListener removeEventListener}.
   */
  this.addEventListener = function () { };

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function () { };

  /**
   * Show or hide geometry based on its scene path.
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [show] - Optional list of scene paths of objects which should be shown
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [hide] - Optional list of scene paths of objects which should be hidden
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the operation succeeded
   */
  this.toggleGeometry = function () { };

  /**
   * Returns the runtime id of the viewport(s).
   * @param {string} [runtimeId] - Optional runtimeId of a specific viewport
   * @return {string|string[]} string, either as a single string or an array of string, depending on the number of viewports
   */
  this.getViewportRuntimeId = function () { };

  /**
   * Returns the container(s) of the viewport(s).
   * @return {HTMLElement | HTMLElement[]} HTMLElement either as a single HTMLElement or an array of HTMLElement, depending on the number of viewports
   */
  this.getContainer = function () { };
};

module.exports = ApiSceneInterface;
