/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *MessagingConstants.js*
 *
 * ### Content
 *   * definition of constants related to messaging (message topics etc)
 *
 * @module MessagingConstants
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
  * Global utilities
  */
var GlobalUtils = require('../util/GlobalUtils');

/**
 * Message topic
 * @typedef {String} MessageTopic
 */

/**
 * Possible message topics
 * @typedef {Object} MessageTopics
 * @property {MessageTopic} DATA - messages related to model data
 * @property {MessageTopic} EXPORT - messages related to exports
 * @property {MessageTopic} EXPORT_AVAILABLE - an export request has been finished, export data is available
 * @property {MessageTopic} EXPORT_REGISTER - registration request for a new export,
 *                                            message will contain a data part of type
 *                                            {@link @module:MessagingConstants~MessageDataType#EXPORT_DEFINITION EXPORT_DEFINITION}
 * @property {MessageTopic} EXPORT_REGISTERED - a new export has been registered,
 *                                            message will contain a data part of type
 *                                            {@link @module:MessagingConstants~MessageDataType#EXPORT_DEFINITION EXPORT_DEFINITION}
 * @property {MessageTopic} EXPORT_REGISTERED_BATCH - a new batch of exports has been registered,
 *                                            message will contain a data part of type
 *                                            {@link @module:MessagingConstants~MessageDataType#EXPORT_DEFINITION EXPORT_DEFINITION}
 * @property {MessageTopic} EXPORT_UPDATE - update of an export definition,
 *                                          typically one of the properties name or order or hidden, message will contain a data part of type
 *                                          {@link @module:MessagingConstants~MessageDataType#EXPORT_DEFINITION EXPORT_DEFINITION}
 * @property {MessageTopic} EXPORT_STATUS - status message regarding an ongoing export
 * @property {MessageTopic} HOVER - messages related to hovering of geometric objects
 * @property {MessageTopic} PARAMETER - messages related to parameters
 * @property {MessageTopic} PARAMETER_REGISTER - registration request for new parameter(s),
 *                                               message will contain a data part of type
 *                                               {@link @module:MessagingConstants~MessageDataType#PARAMETER_DEFINITION PARAMETER_DEFINITION}
 * @property {MessageTopic} PARAMETER_REGISTERED - a new parameter has been registered,
 *                                               message will contain a data part of type
 *                                               {@link @module:MessagingConstants~MessageDataType#PARAMETER_DEFINITION PARAMETER_DEFINITION}
 * @property {MessageTopic} PARAMETER_REGISTERED_BATCH - a new batch of parameters has been registered,
 *                                               message will contain a data part of type
 *                                               {@link @module:MessagingConstants~MessageDataType#PARAMETER_DEFINITION PARAMETER_DEFINITION}
 * @property {MessageTopic} PARAMETER_UPDATE - update of a parameter definition,
 *                                               typically one of the properties name or order or hidden, message will contain a data part of type
 *                                               {@link @module:MessagingConstants~MessageDataType#PARAMETER_DEFINITION PARAMETER_DEFINITION}
 * @property {MessageTopic} PARAMETER_VALUE_UPDATE - update of parameter values,
 *                                               message will contain a data part of type
 *                                               {@link @module:MessagingConstants~MessageDataType#PARAMETER_UPDATE PARAMETER_UPDATE}
 * @property {MessageTopic} PLUGIN - messages related to plugins
 * @property {MessageTopic} PLUGIN_ACTIVE - a plugin changed its status to ACTIVE {@link module:PluginConstantsGlobal~PluginStatus}
 * @property {MessageTopic} PLUGIN_FAILED - a plugin changed its status to FAILED {@link module:PluginConstantsGlobal~PluginStatus}
 * @property {MessageTopic} PLUGIN_LOADED - a plugin changed its status to LOADED {@link module:PluginConstantsGlobal~PluginStatus}
 * @property {MessageTopic} PLUGIN_REGISTERED - a new plugin has been registered by the viewer application
 * @property {MessageTopic} PLUGIN_UNLOADED - a plugin changed its status to UNLOADED {@link module:PluginConstantsGlobal~PluginStatus}
 * @property {MessageTopic} PROCESS - messages related to running processes, when sending a message with this topic and a token,
 *                                    the global message function will automatically attach the token id to the topic, e.g. process.TOKEN
 * @property {MessageTopic} SETTINGS - messages related to settings
 * @property {MessageTopic} SETTINGS_REGISTER - registration request for settings, typically sent by the CommPlugin if it receives settings in reply to a session init request
 * @property {MessageTopic} SETTINGS_UPDATE - the value of a setting got updated - message will include at least one data part of type SETTINGS_UPDATE.
 * @property {MessageTopic} SETTINGS_REGISTERED - processing for a registration request for settings has been
 * @property {MessageTopic} SCENE - messages regarding the 3d scene
 * @property {MessageTopic} SCENE_ANCHOR - messages related to anchor objects
 * @property {MessageTopic} SCENE_ANCHOR_ADD - notification about addition of one or more anchors to the scene,
 *                                             message data may contain several {@link @module:MessagingConstants~AnchorDataMessage AnchorDataMessage} data parts
 * @property {MessageTopic} SCENE_ANCHOR_REMOVE - notification about removal of one or more anchors from the scene,
 *                                                message data may contain several {@link @module:MessagingConstants~AnchorDataMessage AnchorDataMessage} data parts
 * @property {MessageTopic} SCENE_CAMERA - messages related to the camera
 * @property {MessageTopic} SCENE_CAMERA_START - camera movement has started
 * @property {MessageTopic} SCENE_CAMERA_MOVE - camera movement has continued
 * @property {MessageTopic} SCENE_CAMERA_END - camera movement has ended
 * @property {MessageTopic} SCENE_DRAG - messages related to dragging of geometric objects
 * @property {MessageTopic} SCENE_DRAG_START - dragging of objects has started
 * @property {MessageTopic} SCENE_DRAG_MOVE - dragged objects have been moved
 * @property {MessageTopic} SCENE_DRAG_END - dragging of objects has ended
 * @property {MessageTopic} SCENE_FRAMERATE - frame rate every second while rendering
 * @property {MessageTopic} SCENE_HOVER_ON - hovering of geometric object(s) has started
 * @property {MessageTopic} SCENE_HOVER_OVER - geometric object(s) are being hovered
 * @property {MessageTopic} SCENE_HOVER_OFF - hovering of geometric object(s) has ended
 * @property {MessageTopic} SCENE_RENDER - messages related to rendering
 * @property {MessageTopic} SCENE_RENDER_BEAUTY - messages related to beauty rendering
 * @property {MessageTopic} SCENE_RENDER_BEAUTY_START - beauty rendering has started
 * @property {MessageTopic} SCENE_RENDER_BEAUTY_CANCEL - beauty rendering has been cancelled
 * @property {MessageTopic} SCENE_RENDER_BEAUTY_END - beauty rendering has ended
 * @property {MessageTopic} SCENE_SELECT - messages related to selection of geometric objects
 * @property {MessageTopic} SCENE_SELECT_ON - object(s) has been selected
 * @property {MessageTopic} SCENE_SELECT_OFF - object(s) has been deselected
 * @property {MessageTopic} SCENE_SUBSCENE - messages regarding subscene processes
 * @property {MessageTopic} SCENE_SUBSCENE_INIT - a subscene creation process has been started
 * @property {MessageTopic} SCENE_SUBSCENE_OUTPUTVERSION - an output version has been loaded for a subscene
 * @property {MessageTopic} SCENE_SUBSCENE_READY - all information for a subscene has arrived and processed, and subscene is about to be published
 * @property {MessageTopic} SCENE_SUBSCENE_PUBLISHED - a subscene has become visible in the viewer after an update or initialization
 * @property {MessageTopic} SCENE_VISIBILITY - messages regarding visibility of the 3d scene
 * @property {MessageTopic} SCENE_VISIBILITY_ON - 3d scene has become visible
 * @property {MessageTopic} SCENE_VISIBILITY_OFF - 3d scene has become invisible
 * @property {MessageTopic} STATUS - messages related to the status of the viewer
 * @property {MessageTopic} STATUS_BUSY - the viewer entered busy mode, typically contains an APP_STATUS data part
 *                                        consisting of a {@link module:MessagingConstants~ViewerAppStatusMessage ViewerAppStatusMessage}
 * @property {MessageTopic} STATUS_IDLE - the viewer entered idle mode, typically contains an APP_STATUS data part
 *                                        consisting of a {@link module:MessagingConstants~ViewerAppStatusMessage ViewerAppStatusMessage}
 * @property {MessageTopic} STATUS_MESSAGE - a generic message which may be shown to the user, typically contains a STATUS_MESSAGE data part
 *                                        consisting of a {@link module:MessagingConstants~StatusMessage StatusMessage}
 * @property {MessageTopic} STATUS_FAILED - the viewer failed unrecoverable, e.g. in case the WebGL context was lost and could not be recovered,
 *                                          or no WebGL context could be created at all. This message typically contains
 *                                          a STATUS_MESSAGE data part consisting of a {@link module:MessagingConstants~StatusMessage StatusMessage}
 * @property {MessageTopic} VIEWPORTS - message related to the viewports
 * @property {MessageTopic} VIEWPORTS_CREATED - a new viewport has been created
 * @property {MessageTopic} VIEWPORTS_DESTROYED - viewport has been destroyed
 */
var messageTopics = {
  AR: 'ar',
  DATA: 'data',
  EXPORT: 'export',
  EXPORT_AVAILABLE: 'export.available',
  EXPORT_REGISTER: 'export.register',
  EXPORT_REGISTERED: 'export.registered',
  EXPORT_REGISTERED_BATCH: 'export.registered.batch',
  EXPORT_UPDATE: 'export.update',
  EXPORT_STATUS: 'export.status',
  PARAMETER: 'parameter',
  PARAMETER_REGISTER: 'parameter.register',
  PARAMETER_REGISTERED: 'parameter.registered',
  PARAMETER_REGISTERED_BATCH: 'parameter.registered.batch',
  PARAMETER_UPDATE: 'parameter.update',
  PARAMETER_VALUE_UPDATE: 'parameter.value.update',
  PLUGIN: 'plugin',
  PLUGIN_ACTIVE: 'plugin.active',
  PLUGIN_FAILED: 'plugin.failed',
  PLUGIN_LOADED: 'plugin.loaded',
  PLUGIN_REGISTERED: 'plugin.registered',
  PLUGIN_UNLOADED: 'plugin.unloaded',
  PROCESS: 'process',
  SETTINGS: 'settings',
  SETTINGS_REGISTER: 'settings.register',
  SETTINGS_UPDATE: 'settings.update',
  SETTINGS_REGISTERED: 'settings.registered',
  SCENE: 'scene',
  SCENE_ANCHOR: 'scene.anchor',
  SCENE_ANCHOR_ADD: 'scene.anchor.add',
  SCENE_ANCHOR_REMOVE: 'scene.anchor.remove',
  SCENE_CAMERA: 'scene.camera',
  SCENE_CAMERA_START: 'scene.camera.start',
  SCENE_CAMERA_MOVE: 'scene.camera.move',
  SCENE_CAMERA_END: 'scene.camera.end',
  SCENE_DRAG: 'scene.drag',
  SCENE_DRAG_START: 'scene.drag.start',
  SCENE_DRAG_MOVE: 'scene.drag.move',
  SCENE_DRAG_END: 'scene.drag.end',
  SCENE_FRAMERATE: 'scene.framerate',
  SCENE_HOVER: 'scene.hover',
  SCENE_HOVER_ON: 'scene.hover.on',
  SCENE_HOVER_OVER: 'scene.hover.over',
  SCENE_HOVER_OFF: 'scene.hover.off',
  SCENE_RENDER: 'scene.render',
  SCENE_RENDER_BEAUTY: 'scene.render.beauty',
  SCENE_RENDER_BEAUTY_START: 'scene.render.beauty.start',
  SCENE_RENDER_BEAUTY_CANCEL: 'scene.render.beauty.cancel',
  SCENE_RENDER_BEAUTY_END: 'scene.render.beauty.end',
  SCENE_SELECT: 'scene.select',
  SCENE_SELECT_ON: 'scene.select.on',
  SCENE_SELECT_OFF: 'scene.select.off',
  SCENE_SUBSCENE: 'scene.subscene',
  SCENE_SUBSCENE_INIT: 'scene.subscene.init',
  SCENE_SUBSCENE_OUTPUTVERSION: 'scene.subscene.outputversion',
  SCENE_SUBSCENE_READY: 'scene.subscene.ready',
  SCENE_SUBSCENE_PUBLISHED: 'scene.subscene.published',
  SCENE_VISIBILITY: 'scene.visibility',
  SCENE_VISIBILITY_ON: 'scene.visibility.on',
  SCENE_VISIBILITY_OFF: 'scene.visibility.off',
  STATUS: 'status',
  STATUS_BUSY: 'status.busy',
  STATUS_IDLE: 'status.idle',
  STATUS_MESSAGE: 'status.message',
  STATUS_FAILED: 'status.failed',
  VIEWPORTS: 'viewports',
  VIEWPORTS_CREATED: 'viewports.created',
  VIEWPORTS_DESTROYED: 'viewports.destroyed'
};

/**
 * Process error information
 * @typedef {Object} ProcessErrorMessage
 * @property {String} processId
 * @property {Error} error
 */

/**
 * Process status information
 * @typedef {Object} ProcessStatusMessage
 * @property {Boolean} [busy=false] - true: activate busy mode of viewer, false: process not busy (anymore, not yet)
 * @property {module:MessagingConstants~MessageDataType} [state] - an optional loose state description of the process
 * @property {String} [txt] - optional textual description of the process status
 * @property {Object} [data] - optional data attached to the process status
 * @property {Number} [progress=0] - floating point number in interval [0,1] reporting progress of a process
 */

/**
  * Settings update message - informs about the update of a setting
  * @typedef {Object} SettingsUpdateMessage
  * @property {String} key - key of the setting which changed
  * @property {*} valueNew - new value of the setting
  * @property {*} valueOld - old value of the setting
  * @property {*} valuesOld - may be used instead of valueOld in case multiple settings got updated at once
  * @property {*} valuesNew - may be used instead of valuesNew in case multiple settings got updated at once
  * @property {String} namespace - namespace the setting belongs to
  */

/**
  * Viewer application status information
  * @typedef {Object} ViewerAppStatusMessage
  * @property {Boolean} busy - true if the viewer is in busy mode, false if the viewer is in idle mode
  * @property {Number} progress - minimum progress of all processes currently reporting a progress, interval [0,1]
  * @property {Object.<String,MessagingConstants~ProcessStatusMessage>} processes - List of currently active processes
  */

/**
  * 3D point or vector defined by an array of three numbers
  * @typedef {Number[]} Point3d
  */

/**
  * Interaction event data message (drag, hover, select)
  * @typedef {Object} InteractionDataMessage
  * @property {MessageTopic} type - the type of interaction event
  * @property {Object} [obj] - object which was interacted with
  * @property {String} [scenePath] - In case of an event related to a geometric object in the scene, this specifies the path in the scene graph
  * @property {Point3d} [dragPosStart] - In case this is a dragstart event, the starting position of a drag operation
  * @property {Point3d} [dragPosAbs] - In case this is a dragmove or dragend event, the current absolute position of the drag operation
  * @property {Point3d} [dragPosRel] - In case this is a dragmove or dragend event, the current relative position of the drag operation
  * @property {Point3d} [hoverPos] - In case this is a hover event, the ray intersection point
  * @property {Number} [clientX] - horizontal coordinate (according to the current window) of the mouse pointer when a mouse event was triggered
  * @property {Number} [clientY] - vertical coordinate (according to the current window) of the mouse pointer when a mouse event was triggered
  */

/**
  * Anchor data message
  * @typedef {Object} AnchorDataMessage
  * @property {module:MessagingConstants~AnchorDataMessagePart[]} anchors - Array of anchor data objects, one for each anchor
  */

/**
  * Anchor data type definition
  * @typedef {Object} AnchorEventData
  * @property {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} location - The 3D point defining the location of the anchor
  * @property {Number} [containerX] - horizontal coordinate (according to the viewer container) of the 3D location of the anchor
  * @property {Number} [containerY] - vertical coordinate (according to the viewer container) of the 3D location of the anchor
  * @property {Number} [clientX] - horizontal coordinate (according to the current window, scrolling not considered) of the 3D location of the anchor
  * @property {Number} [clientY] - vertical coordinate (according to the current window, scrolling not considered) of the 3D location of the anchor
  * @property {Number} [pageX] - horizontal coordinate (according to the current window) of the 3D location of the anchor
  * @property {Number} [pageY] - vertical coordinate (according to the current window) of the 3D location of the anchor
  * @property {Number} [containerWidth] - the width of the viewer container
  * @property {Number} [containerHeight] - the height of the viewer container
  * @property {String} [viewport] - runtime id of the viewport reporting the anchor position
  * @property {Boolean} [hidden] - true if the anchor is hidden behind other objects, false if not
  * @property {Boolean} [overflow] - true if the 2D anchor position is outside the viewport container
  * @property {Number} [distance] - distance between the camera and the anchor location (perspective camera only)
  * @property {Number} [viewingAngle] - the angle between the camera direction and direction from the camera origin to the anchor
  * @property {module:ApiInterfaceV2~ScenePathType} [scenePath] - this specifies the path of the anchor object in the scene graph
  * @property {String} [format] - optional format description of the anchor object, this property is passed on from the anchor definition (e.g. 'tag2d')
  * @property {Object} [data] - optional data of the anchor object, this property is passed on from the anchor definition (e.g. a Tag2D description)
  * @property {String} [runtimeId] - a unique runtimeId for this anchor
  * @property {Function} [updateFunction] - a function that is provided in the {@link module:MessagingConstants~MessageTopics.SCENE_ANCHOR_ADD SCENE_ANCHOR_ADD} message,
  *                                         which must be called to provide a function that will be called with an updated
  *                                         {@link module:MessagingConstants~AnchorEventData AnchorEventData} object each time the anchor position gets updated
  */
 
/**
  * Camera data message
  * @typedef {Object} CameraDataMessage
  * @property {MessageTopic} type - the type of camera event
  * @property {module:CameraApi~CameraApi#CameraDefinition} [cameraDefinition] - The current camera definition
  * @property {Number} [duration] - The duration of the camera movement
  */

/**
  * Generic message to be shown to the user
  * @typedef {Object} StatusMessage
  * @property {String} [message] - An optional status message which could be displayed to the user
  * @property {module:LoggingConstants~LoggingLevel} [messageLevel=module:LoggingConstants~LoggingLevels#INFO] - Optional
  *           level of message to show to the user
  * @property {String} [messageScope] - Optional scope description of message to show to the user, e.g. 'Session init request', etc
  * @property {Object} [webglreport] - Optional WebGL report for debugging
  * @property {String} [containerId] - Optional id of container this message relates to
  */

/**
 * Message data type
 * @typedef {String} MessageDataType
 */

/**
* Parameter update type
*
* An object whose property names are plugin ids for which parameter values changed.
* The value of each plugin id property is in turn an object mapping parameter ids to new values.
* @typedef {Object.<String, Object.<String, *>>} ParameterUpdateType
*/

/**
 * Possible message data types
 * @typedef {Object} MessageDataTypes
 * @property {MessageDataType} GENERIC - generic or empty data
 * @property {MessageDataType} APP_SETTINGS - an object holding settings for the viewer app
 * @property {MessageDataType} APP_STATUS - an object holding status for the viewer app, type {@link module:MessagingConstants~ViewerAppStatusMessage ViewerAppStatusMessage}
 * @property {MessageDataType} DATA - a model data object
 * @property {MessageDataType} EXPORT_DEFINITION - an export definition object of type {@link module:JSONExport~JSONExportDefinition JSONExportDefinition}
 * @property {MessageDataType} EXPORT_RESULT - an export result object of type {@link module:JSONExport~JSONExportDefinition JSONExportResult}
 * @property {MessageDataType} EXPORT_STATUS - an export status object of type {@link module:JSONExport~JSONExportDefinition JSONExportResult}
 * @property {MessageDataType} PARAMETER_DEFINITION - a parameter definition object of type {@link module:JSONParameter~JSONParameterSet JSONParameterSet}
 * @property {MessageDataType} PARAMETER_SETTINGS - an object holding settings for the parameters
 * @property {MessageDataType} PARAMETER_UPDATE - an object of type {@link module:MessagingConstants~ParameterUpdateType ParameterUpdateType}
 * @property {MessageDataType} PLUGIN_RUNTIME_ID - a plugin runtime id (String)
 * @property {MessageDataType} PROCESS_ERROR - An Error object. The process has ended with the error. No more messages to be expected.
 * @property {MessageDataType} PROCESS_ABORT - A process has been aborted, because it was superseded by another one. No more messages to be expected.
 * @property {MessageDataType} PROCESS_FORK - A process forks into sub-processes, array of new process tokens to listen to, which must be sub-topics of the original token (e.g. TOKEN -> TOKEN.1 and TOKEN.2)
 * @property {MessageDataType} PROCESS_STATUS - An object describing the status of the process: {@link module:MessagingConstants~ProcessStatusMessage ProcessStatusMessage}
 * @property {MessageDataType} PROCESS_SUCCESS - A string describing the successful outcome of the process. No more messages to be expected.
 * @property {MessageDataType} SCENE_CAMERA - An object describing an a camera movement in the scene (object of type {@link module:MessagingConstants~CameraDataMessage CameraDataMessage})
 * @property {MessageDataType} SCENE_INTERACTION - An object describing an interaction with the scene (object of type {@link module:MessagingConstants~InteractionDataMessage InteractionDataMessage})
 * @property {MessageDataType} SCENE_ANCHORDATA - An object describing the current state of one or several anchor objects in the scene (object of type {@link module:MessagingConstants~AnchorDataMessage AnchorDataMessage})
 * @property {MessageDataType} SETTINGS_UPDATE - An object of type {@link module:MessagingConstants~SettingsUpdateMessage SettingsUpdateMessage}, describing the update of a setting
 * @property {MessageDataType} SUBSCENE_UPDATE - An object describing an update of scene assets
 * @property {MessageDataType} SUBSCENE_OUTPUTVERSION_JSON - An object describing the content of an output version (i.e. a subscene part) (object of type {@link module:JSONOutputVersion~JSONOutputVersion JSONOutputVersion})
 * @property {MessageDataType} SUBSCENE_OUTPUTVERSION_OBJ - An object describing the content of an output version (i.e. a subscene part) (object of type {@link module:OutputVersion~OutputVersion OutputVersion})
 * @property {MessageDataType} SUBSCENE_INIT - the number of output ids in a new subscene (Integer)
 * @property {MessageDataType} STATUS_MESSAGE - An object of type {@link module:MessagingConstants~StatusMessage StatusMessage}, describing a generic message which may be shown to the user
 */
var messageDataTypes = {
  GENERIC: 'generic',
  ANCHOR_CREATE_IMAGE: 'anchor.create.image',
  ANCHOR_UPDATE: 'anchor.update',
  APP_SETTINGS: 'app.settings',
  APP_STATUS: 'app.status',
  DATA: 'data',
  EXPORT_DEFINITION: 'export.definition',
  EXPORT_RESULT: 'export.result',
  EXPORT_STATUS: 'export.status',
  OBJECT_PLACEMENT : 'object.placement',
  PARAMETER_DEFINITION: 'parameter.definition',
  PARAMETER_SETTINGS: 'parameter.settings',
  PARAMETER_UPDATE: 'parameter.update',
  PLUGIN_RUNTIME_ID: 'plugin.runtimeId',
  PROCESS_ERROR: 'process.error',
  PROCESS_ABORT: 'process.abort',
  PROCESS_FORK: 'process.fork',
  PROCESS_STATUS: 'process.status',
  PROCESS_SUCCESS: 'process.success',
  STATUS_TRACKING: 'status.tracking',
  STATUS_MAPPING: 'status.mapping',
  SCENE_CAMERA: 'scene.camera',
  SCENE_INTERACTION: 'scene.interaction',
  SCENE_ANCHORDATA: 'scene.anchordata',
  SETTINGS_UPDATE: 'settings.update',
  SUBSCENE_UPDATE: 'subscene.update',
  SUBSCENE_OUTPUTVERSION_JSON: 'subscene.outputversion.json',
  SUBSCENE_OUTPUTVERSION_OBJ: 'subscene.outputversion.obj',
  SUBSCENE_INIT: 'subscene.init',
  STATUS_MESSAGE: 'status.message',
};


/**
 * Message data part
 * @typedef {Object} MessageDataPart
 * @property {MessageDataType} type - type of data part
 * @property {Object} data - data part
 */


/**
* Message origin or creator info
* @typedef {Object} MessageOrigin
* @property {String} [plugin] - Optional runtime id of the sending plugin {@link module:PluginInterface~PluginInterface#runtimeId}, set automatically by message function injected to plugin
* @property {String} [namepath] - Optional namepath of the sender (without module prefix), see {@link http://usejsdoc.org/about-namepaths.html}
*/

/**
* Token attached to message - to be passed on by any message or other operation in response to this message
* @typedef {Object} MessageToken
* @property {String} id - Id of the token, a random one will be created if none exists.
* @property {MessageOrigin} creator - Creator of the token
* @property {*} [payload] - Optional payload to pass along with the token
* @property {Number} serial - Timestamp of creation or first use of the token, number of milliseconds elapsed since January 1, 1970 00:00:00 UTC
*/

/**
* Helper function for creating a token
* Create a MessageToken that has id, serial, and optionally creator set
* @param {String|MessageToken} [token] - token to start from
* @param {String|MessageOrigin} [creator] - creator to record in the token
*/
var makeMessageToken = function(token_, creator_) {
  let t = {};
  if (typeof token_ === 'string') {
    t.id = token_ + '';
  }
  else if (token_ !== null && typeof token_ === 'object') {
    if (typeof token_.id === 'string')
      t.id = token_.id + '';
    if (typeof token_.creator === 'string')
      t.creator = token_.creator + '';
    if (typeof token_.serial === 'number')
      t.serial = token_.serial + '';
    t.payload = token_.payload;
    t.attributes = token_.attributes;
  }
  if (typeof t.id !== 'string' || t.id.length === 0)
    t.id = GlobalUtils.createRandomId();
  if (typeof t.creator !== 'string' && typeof creator_ === 'string')
    t.creator = creator_ + '';
  if (typeof t.serial !== 'number')
    t.serial = Date.now();
  return t;
};

/**
 * Import globally defined logging constants
 */
var loggingConstants = require('./LoggingConstants');

/**
 * Use globally defined logging levels
 */
var loggingLevels = loggingConstants.loggingLevels;

/**
 * Definition of default settings for messaging
 */
var defaultSettings = {
  messageLoggingLevel: loggingLevels.NONE,
};

/**
 * Collection of all constants
 */
var messagingConstants = {
  messageTopics: messageTopics,
  messageDataTypes: messageDataTypes,
  defaultSettings: defaultSettings
};


/**
 * Export a deep copy of the constants, attach the
 */
let exp = GlobalUtils.deepCopy(messagingConstants);
exp.makeMessageToken = makeMessageToken;
module.exports = exp;
