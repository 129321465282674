"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDControlsSettings = void 0;
var SDControlsSettings = /** @class */ (function () {
    // #endregion Properties (3)
    // #region Constructors (1)
    function SDControlsSettings(_api, _editMode, _useCommitMode) {
        this._api = _api;
        this._editMode = _editMode;
        this._useCommitMode = _useCommitMode;
        // #region Properties (3)
        this._settings = {};
        this._settingsDefinitions = this._api.getSettingDefinitions();
        var cameraType = this._api.scene.camera.TYPE;
        this.createHeaders();
        this.createGeneralSettings();
        this.createLightSettings();
        this.createBackgroundSettings();
        this.createCameraSettings();
    }
    Object.defineProperty(SDControlsSettings.prototype, "settings", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return this._settings;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Public Methods (1)
    SDControlsSettings.prototype.createLightSetting = function (lightDef) {
        var parameters = [];
        var orderId = 0;
        for (var key in lightDef.properties) {
            switch (key) {
                case "intensity":
                    parameters.push({
                        type: "light_slider" /* LIGHT_SLIDER */,
                        min: 0,
                        max: 5,
                        id: key,
                        groupId: 2,
                        name: key,
                        step: 0.01,
                        lightDef: lightDef,
                        value: lightDef.properties.intensity
                    });
                    break;
                case "angle":
                case "penumbra":
                    parameters.push({
                        type: "light_slider" /* LIGHT_SLIDER */,
                        min: 0,
                        max: Math.PI / 2,
                        id: key,
                        groupId: 2,
                        name: key,
                        step: 0.01,
                        lightDef: lightDef,
                        value: key == "angle" ? lightDef.properties.angle : lightDef.properties.penumbra
                    });
                    break;
                case "distance":
                    parameters.push({
                        type: "light_slider" /* LIGHT_SLIDER */,
                        id: key,
                        groupId: 2,
                        name: key,
                        lightDef: lightDef,
                        value: lightDef.properties.distance
                    });
                    break;
                case "decay":
                    parameters.push({
                        type: "light_slider" /* LIGHT_SLIDER */,
                        min: 0,
                        max: 2,
                        id: key,
                        groupId: 2,
                        name: key,
                        step: 0.01,
                        lightDef: lightDef,
                        value: lightDef.properties.decay
                    });
                    break;
                case "color":
                    parameters.push({
                        id: key,
                        name: key,
                        groupId: 2,
                        lightDef: lightDef,
                        type: "light_color" /* LIGHT_COLOR */,
                        value: lightDef.properties.color
                    });
                    break;
                case "groundColor":
                    parameters.push({
                        id: key,
                        name: "Ground Color",
                        groupId: 2,
                        lightDef: lightDef,
                        type: "light_color" /* LIGHT_COLOR */,
                        value: lightDef.properties.groundColor
                    });
                    break;
                case "skyColor":
                    parameters.push({
                        id: key,
                        name: "Sky Color",
                        groupId: 2,
                        lightDef: lightDef,
                        type: "light_color" /* LIGHT_COLOR */,
                        value: lightDef.properties.skyColor
                    });
                    break;
                case "direction":
                case "position":
                case "target":
                    parameters.push({
                        type: "light_vector" /* LIGHT_VECTOR3 */,
                        id: key,
                        groupId: 2,
                        name: key,
                        step: 0.01,
                        lightDef: lightDef,
                        value: lightDef.properties[key]
                    });
                    break;
                case "castShadow":
                    parameters.push({
                        type: "light_checkbox" /* LIGHT_CHECKBOX */,
                        id: key,
                        groupId: 2,
                        name: "Cast Shadow",
                        lightDef: lightDef,
                        value: lightDef.properties[key],
                    });
                    break;
            }
        }
        return {
            id: lightDef.id,
            order: SDControlsSettings.lightOrderId++,
            groupId: 2,
            type: "light_definition" /* LIGHT_DEFINITION */,
            name: lightDef.id,
            lightDef: lightDef,
            title: "Click to edit the properties of this light source.",
            parameters: parameters
        };
    };
    // #endregion Public Methods (1)
    // #region Private Methods (4)
    SDControlsSettings.prototype.createBackgroundSettings = function () {
        // background color
        this.settings["render.clearColor"] = {
            value: this._api.getSetting("render.clearColor"),
            setting: "render.clearColor",
            id: "render.clearColor",
            type: "color" /* COLOR */,
            name: "Background Color",
            title: "Background color of the viewer if no environment is visible",
            groupId: 3,
            order: 1,
        };
        // background alpha
        this.settings["render.clearAlpha"] = {
            id: "render.clearAlpha",
            setting: "render.clearAlpha",
            type: "slider" /* SLIDER */,
            name: "Background Alpha",
            title: "Background transparency of the viewer if no environment is visible",
            order: 2,
            groupId: 3,
            min: 0,
            max: 1,
            step: 0.01,
        };
        // env toggle
        this.settings["material.environmentMapAsBackground"] = {
            setting: "material.environmentMapAsBackground",
            id: "material.environmentMapAsBackground",
            type: "checkbox" /* CHECKBOX */,
            name: "Show Environment",
            title: "Make the environment map visible",
            order: 3,
            groupId: 3
        },
            // env dropdown
            this.settings["material.environmentMap_1"] = {
                groupId: 3,
                order: 4,
                id: "material.environmentMap",
                setting: "material.environmentMap",
                type: "dropdown" /* DROPDOWN */,
                name: "Choose Environment",
                title: "Choose an environment map",
                choices: [
                    "none",
                    "default",
                    "default_bw",
                    "blurred_lights",
                    "georgentor",
                    "georgentor_blur",
                    "georgentor_blue_blur",
                    "georgentor_bw_blur",
                    "levelsets",
                    "lythwood_field",
                    "mountains",
                    "ocean",
                    "piazza_san_marco",
                    "residential_garden",
                    "room_abstract_1",
                    "sky",
                    "storage_room",
                    "storm",
                    "subway_entrance",
                    "subway_entrance_bw_blur",
                    "white",
                    "yokohama"
                ]
            };
        // env resolution dropdown
        this.settings["material.environmentMapResolution"] = {
            groupId: 3,
            order: 5,
            id: "material.environmentMapResolution",
            setting: "material.environmentMapResolution",
            type: "dropdown" /* DROPDOWN */,
            name: "Environment resolution",
            title: "Choose the pixel resolution of the environment map",
            choices: ["256", "512", "1024", "2048"]
        };
        // env input text
        this.settings["material.environmentMap_2"] = {
            groupId: 3,
            order: 6,
            id: "material.environmentMap_2",
            setting: "material.environmentMap",
            type: "string" /* STRING */,
            name: "Custom Environment",
            title: "Enter the url of a custom cube map",
            max: 500
        };
    };
    SDControlsSettings.prototype.createGeneralSettings = function () {
        this.settings["render.shadows"] = {
            id: "render.shadows",
            setting: "render.shadows",
            type: "checkbox" /* CHECKBOX */,
            name: "Shadows",
            value: this._api.getSetting("scene.render.shadows"),
            title: this._settingsDefinitions["scene.render.shadows"].description,
            order: 1,
            groupId: 1
        };
        // AO
        this.settings["render.ambientOcclusion"] = {
            setting: "render.ambientOcclusion",
            id: "render.ambientOcclusion",
            value: this._api.getSetting("render.ambientOcclusion"),
            type: "checkbox" /* CHECKBOX */,
            name: "Ambient Occlusion",
            title: this._settingsDefinitions["scene.render.ambientOcclusion"].description,
            order: 2,
            groupId: 1
        };
        // bump amplitude
        this.settings["defaultMaterial.bumpAmplitude"] = {
            id: "defaultMaterial.bumpAmplitude",
            groupId: 1,
            order: 3,
            value: this._api.getSetting("defaultMaterial.bumpAmplitude"),
            setting: "defaultMaterial.bumpAmplitude",
            type: "slider" /* SLIDER */,
            name: "Bump Amplitude",
            title: this._settingsDefinitions['defaultMaterial.bumpAmplitude'].description,
            step: 0.001,
            max: 1 + SDControlsSettings.EPS,
        };
        // ground plane toggle
        this.settings["groundPlaneVisibility"] = {
            value: this._api.getSetting("groundPlaneVisibility"),
            setting: "groundPlaneVisibility",
            id: "groundPlaneVisibility",
            type: "checkbox" /* CHECKBOX */,
            name: "Ground Plane",
            title: this._settingsDefinitions["scene.groundPlaneVisibility"].description,
            order: 4,
            groupId: 1
        };
        // grid toggle
        this.settings["gridVisibility"] = {
            value: this._api.getSetting("gridVisibility"),
            setting: "gridVisibility",
            id: "gridVisibility",
            type: "checkbox" /* CHECKBOX */,
            name: "Grid",
            title: this._settingsDefinitions["scene.gridVisibility"].description,
            order: 5,
            groupId: 1
        };
        // point size
        this.settings["render.pointSize"] = {
            value: this._api.getSetting("render.pointSize"),
            id: "render.pointSize",
            order: 6,
            groupId: 1,
            setting: "render.pointSize",
            name: "Point Size",
            title: this._settingsDefinitions['scene.render.pointSize'].description,
            type: "slider" /* SLIDER */,
            min: 0.001,
            max: 100 + SDControlsSettings.EPS,
            step: 0.001
        };
        // default material color
        this.settings["defaultMaterial.color"] = {
            value: this._api.getSetting("defaultMaterial.color"),
            id: "defaultMaterial.color",
            order: 7,
            groupId: 1,
            setting: "defaultMaterial.color",
            name: "Default Material Color",
            title: this._settingsDefinitions['defaultMaterial.color'].description,
            type: "color" /* COLOR */
        };
        this.settings["commitParameters"] = {
            value: this._api.getSetting("commitParameters") || this._useCommitMode,
            setting: "commitParameters",
            id: "commitParameters",
            type: "checkbox" /* CHECKBOX */,
            name: "Parameter validation",
            title: this._settingsDefinitions["commitParameters"].description,
            order: 8,
            groupId: 1
        };
    };
    SDControlsSettings.prototype.createHeaders = function () {
        this._settings["documentation.general"] = {
            content: '<div class="uk-text-center uk-padding-small uk-width-1-1 uk-background-muted"><span>General&nbsp;<a href="https://www.shapediver.com/support/viewer/settings/general" class="sdv-control-brand-color" target="_blank" uk-icon="icon:question"></a></span></div>',
            id: 'documentation.general',
            type: "statichtml" /* STATICHTML */,
            groupId: 1,
            order: 0
        };
        this._settings["documentation.lights"] = {
            content: '<div class="uk-text-center uk-padding-small uk-width-1-1 uk-background-muted"><span>Lighting&nbsp;<a href="https://www.shapediver.com/support/viewer/settings/lighting" class="sdv-control-brand-color" target="_blank" uk-icon="icon:question"></a></span></div>',
            id: 'documentation.lights',
            type: "statichtml" /* STATICHTML */,
            groupId: 2,
            order: 0
        };
        this._settings["documentation.environment"] = {
            content: '<div class="uk-text-center uk-padding-small uk-width-1-1 uk-background-muted"><span>Environment&nbsp;<a href="https://www.shapediver.com/support/viewer/settings/environment" class="sdv-control-brand-color" target="_blank" uk-icon="icon:question"></a></span></div>',
            id: 'documentation.environment',
            type: "statichtml" /* STATICHTML */,
            groupId: 3,
            order: 0
        };
        this._settings["documentation.camera"] = {
            content: '<div class="uk-text-center uk-padding-small uk-width-1-1 uk-background-muted"><span>Camera&nbsp;<a href="https://www.shapediver.com/support/viewer/settings/camera" class="sdv-control-brand-color" target="_blank" uk-icon="icon:question"></a></span></div>',
            id: 'documentation.camera',
            type: "statichtml" /* STATICHTML */,
            groupId: 4,
            order: 0
        };
    };
    SDControlsSettings.prototype.createLightSettings = function () {
        this.settings["add.lights.scene"] = {
            type: "add_light_modal" /* ADD_LIGHT_MODAL */,
            id: "add.lights.scene",
            groupId: 2,
            order: 997
        };
        if (this._editMode) {
            this.settings["save.lights.scene"] = {
                type: "save_light_scene_modal" /* SAVE_LIGHT_SCENE_MODAL */,
                id: "save.lights.scene",
                groupId: 2,
                order: 998
            };
        }
        this.settings["lights.lightScene"] = {
            id: "lights.lightScene",
            type: "load_light_scene" /* LOAD_LIGHT_SCENE */,
            groupId: 2,
            order: 999
        };
    };
    SDControlsSettings.prototype.createCameraSettings = function () {
        this.settings["camera.cameraTypes.active"] = {
            groupId: 4,
            setting: "camera.cameraTypes.active",
            order: 1,
            id: "camera.cameraTypes.active",
            type: "camera" /* CAMERA */,
            name: "Camera Type",
            title: "Choose an environment map",
            value: this._api.getSetting("camera.cameraTypes.active"),
            choices: ["Perspective", "Top", "Bottom", "Right", "Left", "Back", "Front"]
        };
        // fov
        // zoom factor
        this.settings["camera.zoomExtentsFactor"] = {
            value: this._api.getSetting("camera.zoomExtentsFactor"),
            id: "camera.zoomExtentsFactor",
            order: 11,
            groupId: 4,
            setting: "camera.zoomExtentsFactor",
            name: "Zoom Factor",
            title: this._settingsDefinitions['scene.camera.zoomExtentsFactor'].description,
            type: "slider" /* SLIDER */,
            min: 0.1,
            max: 10 + SDControlsSettings.EPS,
            step: 0.01
        };
        // camera auto adjust
        this.settings["camera.autoAdjust"] = {
            id: "camera.autoAdjust",
            setting: "camera.autoAdjust",
            type: "checkbox" /* CHECKBOX */,
            name: "Camera adjust",
            title: this._settingsDefinitions['scene.camera.autoAdjust'].description,
            groupId: 4,
            order: 12
        };
        // camera movement duration
        this.settings["camera.cameraMovementDuration"] = {
            value: this._api.getSetting("camera.cameraMovementDuration"),
            id: "camera.cameraMovementDuration",
            order: 13,
            groupId: 4,
            setting: "camera.cameraMovementDuration",
            name: "Adjust duration [msec]",
            title: this._settingsDefinitions['scene.camera.cameraMovementDuration'].description,
            type: "slider" /* SLIDER */,
            min: 0,
            max: 2500
        };
        // camera revert at mouse up
        this.settings["camera.revertAtMouseUp"] = {
            id: "camera.revertAtMouseUp",
            setting: "camera.revertAtMouseUp",
            type: "checkbox" /* CHECKBOX */,
            name: "Camera revert",
            title: this._settingsDefinitions['scene.camera.revertAtMouseUp'].description,
            groupId: 4,
            order: 14
        };
        // camera revert duration
        this.settings["camera.revertAtMouseUpDuration"] = {
            value: this._api.getSetting("camera.revertAtMouseUpDuration"),
            id: "camera.revertAtMouseUpDuration",
            order: 15,
            groupId: 4,
            setting: "camera.revertAtMouseUpDuration",
            name: "Revert duration [msec]",
            title: this._settingsDefinitions['scene.camera.revertAtMouseUpDuration'].description,
            type: "slider" /* SLIDER */,
            min: 0,
            max: 2500
        };
    };
    SDControlsSettings.lightOrderId = 1;
    SDControlsSettings.EPS = 0.000001;
    return SDControlsSettings;
}());
exports.SDControlsSettings = SDControlsSettings;
