/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ShapeDiverParameters.js*
 *
 * ### Content
 *   * Implementation of the {@link module:ParameterInterface~ParameterInterface} for various types of parameters
 *
 * @module ShapeDiverParameters
 * @author Mathias Höbinger <mathias@shapediver.com>
 */

/**
 * Global utilities
 */
var GlobalUtils = require('../util/GlobalUtils');

/**
 * Utility for converting color strings
 */
var toTinyColor = require('../util/toTinyColor');

// check for uuid - regex from https://www.npmjs.com/package/is-uuid
const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
//const uuidv5Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;


// collect constructors for export
module.exports = {};

/**
 * Default parameter settings
 */
var defaultSettings = {strictMode: true};

/**
 * Constructor of the CommonParameter class.
 *
 * @classdesc Generic parameter mixin
 *
 * Provides basic type checking functionality and mixes in functionality used by implementation of all parameters.
 * This is a partial implementation of {@link module:ParameterInterface~ParameterInterface} and must be extended further by specialised parameters.
 *
 * @class CommonParameter
 *
 * @implements {@link module:ParameterInterface~ParameterInterface}
 * @extends module:ParameterInterface~ParameterInterface
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {Number|String|Boolean} settings.defval - Default value of parameter (must correspond to valuetype)
 * @param {String} [settings.valuetype] - JavaScript value type (typeof) of parameter, one of ("number", "string", "boolean"), inferred from defval if not specified
 * @param {Number|String|Boolean} [settings.value] - Value of parameter (must correspond to valuetype)
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var CommonParameter = function(___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // Commonly shared functionality
  //
  ////////////
  ////////////
  require('../mixins/GlobalMixin').call(this);

  ////////////
  ////////////
  //
  // inject parameter interface
  //
  ////////////
  ////////////
  require('../interfaces/ParameterInterface').call(this);

  ////////////
  ////////////
  //
  // settings mixin
  //
  ////////////
  ////////////

  /**
   * Checks valuetype of given value
   * @param  {*} value - value to check
   * @param  {String} valuetype - valuetype to check for
   * @return {Boolean}   True if value corresponds to the value type.
   */
  var _checkValueType = function(value, valuetype) {
    if ( Array.isArray(value) && valuetype === 'array' ) {
      return true;
    } else if ( typeof value === valuetype ) {
      return true;
    }
    return false;
  };

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined ) {
    ___settings.isValid = true;
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 ) {
    ___settings.name = 'Generic parameter';
  }
  // value type - infer from defval if not present, or use number as fallback
  if ( ___settings.valuetype === undefined || typeof ___settings.valuetype !== 'string' || ___settings.valuetype.length === 0 ) {
    if ( ___settings.defval !== undefined ) {
      if ( Array.isArray(___settings.defval) ) {
        ___settings.valuetype = 'array';
      } else {
        ___settings.valuetype = typeof ___settings.defval;
      }
    } else {
      ___settings.valuetype = 'number';
      ___settings.isValid = false;
    }
  }
  // default value
  if ( ___settings.defval === undefined || !_checkValueType( ___settings.defval, ___settings.valuetype ) ) {
    ___settings.defval = 0;
    ___settings.isValid = false;
  }
  // value
  if ( ___settings.value === undefined ) {
    ___settings.value = ___settings.defval;
  } else if ( !_checkValueType( ___settings.value, ___settings.valuetype ) ) {
    ___settings.isValid = false;
  }

  // inject settings mixin
  require('../mixins/SettingsMixin').call(this, ___settings);

  // forbid update of valuetype
  this.registerHook('valuetype',() => false);

  // type checking before update of default value
  this.registerHook('defval',(val) => that.checkValue(val));

  // type checking before update of value
  this.registerHook('value',(val) => that.checkValue(val));

  // forbid update of setting isValid
  this.registerHook('isValid',() => false);

  ////////////
  ////////////
  //
  // inject logging mixin
  //
  ////////////
  ////////////
  require('../mixins/LoggingMixin').call(this);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  /**
   * Checks if the parameter definition is valid.
   * Essentially we use this to check whether the settings used for construction of a parameter were valid,
   * and the resulting parameter object is usable.
   * @return {Boolean}   True if parameter object was constructed successfully.
   */
  this.isValid = function() {
    return that.getSetting('isValid');
  };

  /**
   * Checks if given value qualifies as a valid parameter value.
   * @param  {*} value - value to check (must correspond to valuetype setting)
   * @return {Boolean}   True if value is a valid parameter value.
   */
  this.checkValue = function(value) {
    return _checkValueType(value, that.getSettingShallow('valuetype'));
  };

  /**
   * Set new parameter value
   * @param  {*} value - The new parameter value (must correspond to valuetype setting)
   * @return {Boolean}         True if new value is valid and could be set
   */
  this.setValue = function(value) {
    return that.updateSetting('value', value);
  };

  /**
   * Retrieves the current parameter value.
   * @return {*} The current parameter value
   */
  this.getValue = function() {
    return that.getSetting('value');
  };

  /**
   * Retrieves the current parameter value as a properly formatted string
   * @return {String} String representation of the current value
   */
  this.getValueString = function() {
    return that.getValue().toString();
  };

  /**
   * Set parameter value to new value from a compatible string representation
   * @param  {String} value - The new parameter value represented by a string
   * @return {Boolean}      True if new value is valid and could be set
   */
  this.parseValue = function(str) {
    var scope = 'CommonParameter.parseValue';
    if (typeof str !== 'string')
      return false;
    var valuetype = that.getSettingShallow('valuetype');
    if ( valuetype === 'string' )
      return that.setValue(str);
    else if ( valuetype === 'number' )
      return that.setValue( parseFloat(str) );
    else if ( valuetype === 'boolean' ) {
      if ( str.toLowerCase() === 'false' )
        return that.setValue( false );
      else
        return that.setValue( Boolean(str) );
    } else if ( valuetype === 'object' || valuetype === 'array' ) {
      try {
        return that.setValue( JSON.Parse(str) );
      } catch (e) {
        that.debug(scope, e);
        return false;
      }
    } else {
      return false;
    }
  };

  /**
   * Compare if the given value is different from the currently set one
   * @param  {String} value - The parameter value to compare with
   * @return {Boolean}  true if new value is different, false if it is the same
   */
  this.isValueDifferent = function(v) {
    let valuetype = that.getSettingShallow('valuetype');
    let value = that.getSettingShallow('value');
    if ( valuetype === 'array' || valuetype === 'object') {
      // in case of array or object value type, compare JSON stringified values
      let value_str = JSON.stringify(value, null, 0);
      let v_str = JSON.stringify(v, null, 0);
      return ( value_str !== v_str );
    } else {
      return ( value !== v );
    }
  };

  /**
   * Return a description of the whole parameter, for logging and debugging
   * @return {String}      String representation describing the parameter
   */
  that.toString = function() {
    var str = 'Parameter "' + that.getSetting('name') + '"';
    str += '\n\t' + 'settings: ' + JSON.stringify(that.getSettings(), null, 0);
    return str;
  };

  /**
  * Check if parameter accepts blobs
  */
  this.acceptsBlobs = function() {
    return false;
  };

  /**
   * Checks if given value qualifies as a valid parameter value.
   * @param  {File|Blob} value - value to check
   * @return {Boolean}   True if value is a valid parameter value.
   */
  this.checkBlob = function(value) {
    if ( !that.acceptsBlobs() ) {
      return false;
    }
    // check for Blob/File object
    if ( !(value instanceof Blob) ) {
      return false;
    }
    // check max file size
    if ( value.size > that.getSettingShallow('max') ) {
      return false;
    }
    // if automatic mime type detection does not work, guess mime type
    if ( value.type.length > 0 ) {
      value._type = value.type;
    } else {
      value._type = GlobalUtils.guessMimeTypeFromFilename(value.name);
    }
    // check file format
    if ( !that.getSettingShallow('format').includes(value._type) ) {
      return false;
    }
    return true;
  };

  return this;
};
module.exports['CommonParameter'] = CommonParameter;


/**
 * Constructor of the FloatParameter class
 *
 * @classdesc A floating number parameter
 *
 * Any newly set value will be silently truncated to the specified number
 * of decimal digits.
 *
 * @class FloatParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~CommonParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {Number} settings.defval - Default value of parameter
 * @param {Number} [settings.decimalplaces=20] - Number of decimal digits
 * @param {Number} [settings.min=-Number.MAX_VALUE] - Minimum value
 * @param {Number} [settings.max=Number.MAX_VALUE] - Maximum value
 * @param {Number} [settings.value] - Value of parameter
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var FloatParameter = function(___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // CommonParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined )
    ___settings.isValid = true;
  // if we are not operating in strict mode, accept strings instead of numbers
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    ['decimalplaces'].forEach( function(key) {
      if ( typeof ___settings[key] === 'string' ) {
        ___settings[key] = parseInt(___settings[key]);
      }
    });
    ['min', 'max', 'defval', 'value'].forEach( function(key) {
      if ( typeof ___settings[key] === 'string' ) {
        ___settings[key] = parseFloat(___settings[key]);
      }
    });
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 )
    ___settings.name = 'Float parameter';
  // valuetype must be number
  ___settings.valuetype = 'number';
  // decimalplaces
  if ( ___settings.decimalplaces === undefined || typeof ___settings.decimalplaces !== 'number' || ___settings.decimalplaces < 0 )
    ___settings.decimalplaces = 20;
  // max and min value
  if ( ___settings.min === undefined || typeof ___settings.min !== 'number' ) {
    ___settings.min = -Number.MAX_VALUE;
  }
  if ( ___settings.max === undefined || typeof ___settings.max !== 'number' ) {
    ___settings.max = Number.MAX_VALUE;
  }
  if ( ___settings.min > ___settings.max ) {
    ___settings.min = ___settings.max;
    ___settings.isValid = false;
  }
  // defval
  if ( ___settings.defval === undefined || typeof ___settings.defval !== 'number' || ___settings.defval < ___settings.min || ___settings.defval > ___settings.max ) {
    ___settings.defval = ___settings.min;
    ___settings.isValid = false;
  }
  // value
  if ( ___settings.value === undefined || typeof ___settings.value !== 'number' || ___settings.value < ___settings.min || ___settings.value > ___settings.max ) {
    ___settings.value = ___settings.defval;
  }


  // inject CommonParameter
  CommonParameter.call(this, ___settings);

  // forbid update of decimalplaces (or implement check)
  this.registerHook('decimalplaces',() => false);

  // allow update of min if value stays valid
  this.registerHook('min',(newmin) => {
    if ( that.getSettingShallow('value') >= newmin )
      return true;
    return false;
  });

  // allow update of max if value stays valid
  this.registerHook('max',(newmax) => {
    if ( that.getSettingShallow('value') <= newmax ) {
      return true;
    }
    return false;
  });

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  var _super_setValue = this.setValue;
  /**
   * Set new float value
   * @param  {Number} value - The new float value
   * @return {Boolean}         True if new value is valid and could be set
   */
  this.setValue = function(value) {
    if ( typeof value === 'string' && !that.getSettingShallow('strictMode') )
      value = parseFloat(value);
    if ( typeof value !== 'number')
      return false;
    var _value = value;
    var decimalplaces = that.getSettingShallow('decimalplaces');
    if ( decimalplaces < Number.MAX_SAFE_INTEGER ) {
      _value = parseFloat( value.toFixed(decimalplaces) );
    }
    return _super_setValue(_value);
  };

  /**
   * Retrieve the current float value as a properly formatted string
   * @return {String} String representation of the current float value
   */
  this.getValueString = function() {
    var decimalplaces = that.getSettingShallow('decimalplaces');
    if (decimalplaces < Number.MAX_SAFE_INTEGER) {
      return that.getValue().toFixed(decimalplaces);
    } else {
      return that.getValue();
    }
  };

  var _super_checkValue = this.checkValue;
  /**
   * Checks if given float qualifies as a valid parameter value.
   * @param  {Number} value - float value to check
   * @return {Boolean}   True if value is a valid parameter value.
   */
  this.checkValue = function(value) {
    if ( typeof value === 'string' && !that.getSettingShallow('strictMode') )
      value = parseFloat(value);
    if ( !_super_checkValue(value) )
      return false;
    return ( value >= that.getSettingShallow('min') && value <= that.getSettingShallow('max') );
  };

  /**
   * Get definition of paramater
   * @return {module:JSONParameter~JSONParameter}   Definition of parameter if available, undefined otherwise
   */
  this.getDefinition = function() {
    return that.getSettings([
      'id',
      'name',
      'plugin',
      'note',
      'type',
      'decimalplaces',
      'defval',
      'group',
      'max',
      'min',
      'visualization',
      '_name',
      'order',
      'hidden'
    ]);
  };

  return this;
};
module.exports['FloatParameter'] = FloatParameter;


/**
 * Constructor of the IntegerParameter class
 *
 * @classdesc An integer number parameter
 *
 * @class IntegerParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~FloatParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {Number} settings.defval - Default value of parameter
 * @param {Number} [settings.min=Number.MIN_SAFE_INTEGER] - Minimum value
 * @param {Number} [settings.max=Number.MAX_SAFE_INTEGER] - Maximum value
 * @param {Number} [settings.value] - Value of parameter
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var IntegerParameter = function(___settings) {

  // var that = this;

  ////////////
  ////////////
  //
  // FloatParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined )
    ___settings.isValid = true;
  // if we are not operating in strict mode, accept strings instead of numbers
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    ['decimalplaces', 'min', 'max', 'defval', 'value'].forEach( function(key) {
      if ( typeof ___settings[key] === 'string' ) {
        ___settings[key] = parseInt(___settings[key]);
      }
    });
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 )
    ___settings.name = 'Integer parameter';
  // decimalplaces
  if ( ___settings.decimalplaces === undefined )
    ___settings.decimalplaces = 0;
  if ( typeof ___settings.decimalplaces !== 'number' || ___settings.decimalplaces !== 0 ) {
    ___settings.decimalplaces = 0;
    ___settings.isValid = false;
  }
  // max and min value
  if ( ___settings.min === undefined || typeof ___settings.min !== 'number' ) {
    ___settings.min = Number.MIN_SAFE_INTEGER;
  }
  if ( ___settings.max === undefined || typeof ___settings.max !== 'number' ) {
    ___settings.max = Number.MAX_SAFE_INTEGER;
  }
  if ( ___settings.min > ___settings.max ) {
    ___settings.min = ___settings.max;
    ___settings.isValid = false;
  }
  // defval
  if ( ___settings.defval === undefined || typeof ___settings.defval !== 'number' || ___settings.defval < ___settings.min || ___settings.defval > ___settings.max || Math.round(___settings.defval) !== ___settings.defval) {
    ___settings.defval = ___settings.min;
    ___settings.isValid = false;
  }
  // value
  if ( ___settings.value === undefined || typeof ___settings.value !== 'number' || ___settings.value < ___settings.min || ___settings.value > ___settings.max || Math.round(___settings.value) !== ___settings.value) {
    ___settings.value = ___settings.defval;
  }


  // inject FloatParameter
  FloatParameter.call(this, ___settings);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  return this;
};
module.exports['IntegerParameter'] = IntegerParameter;


/**
 * Constructor of the EvenIntegerParameter class
 *
 * @classdesc An even integer number parameter
 *
 * @class EvenIntegerParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~IntegerParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {Number} settings.defval - Default value of parameter
 * @param {Number} [settings.min=Number.MIN_SAFE_INTEGER] - Minimum value
 * @param {Number} [settings.max=Number.MAX_SAFE_INTEGER] - Maximum value
 * @param {Number} [settings.value] - Value of parameter
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var EvenIntegerParameter = function(___settings) {

  //var that = this;

  ////////////
  ////////////
  //
  // IntegerParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined )
    ___settings.isValid = true;
  // if we are not operating in strict mode, accept strings instead of numbers
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    ['decimalplaces', 'min', 'max', 'defval', 'value'].forEach( function(key) {
      if ( typeof ___settings[key] === 'string' ) {
        ___settings[key] = parseInt(___settings[key]);
      }
    });
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 )
    ___settings.name = 'Even integer parameter';
  // max and min value
  if ( ___settings.min === undefined || typeof ___settings.min !== 'number' ) {
    ___settings.min = Number.MIN_SAFE_INTEGER;
  }
  if ( ___settings.max === undefined || typeof ___settings.max !== 'number' ) {
    ___settings.max = Number.MAX_SAFE_INTEGER;
  }
  if ( ___settings.min > ___settings.max ) {
    ___settings.min = ___settings.max;
    ___settings.isValid = false;
  }
  // defval
  if ( ___settings.defval === undefined || typeof ___settings.defval !== 'number' || ___settings.defval < ___settings.min || ___settings.defval > ___settings.max || ___settings.defval % 2 !== 0 ) {
    ___settings.defval = ___settings.min;
    ___settings.isValid = false;
  }
  // value
  if ( ___settings.value === undefined || typeof ___settings.value !== 'number' || ___settings.value < ___settings.min || ___settings.value > ___settings.max || ___settings.value % 2 !== 0 )
    ___settings.value = ___settings.defval;


  // inject IntegerParameter
  IntegerParameter.call(this, ___settings);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  var _super_checkValue = this.checkValue;
  /**
   * Checks if given value qualifies as a valid parameter value (even integer).
   * @param  {Number} value - value to check
   * @return {Boolean}   True if value is a valid parameter value (even integer).
   */
  this.checkValue = function(value) {
    if ( !_super_checkValue(value) )
      return false;
    return ( value % 2 === 0 );
  };

  return this;
};
module.exports['EvenIntegerParameter'] = EvenIntegerParameter;


/**
 * Constructor of the OddIntegerParameter class
 *
 * @classdesc An odd integer number parameter
 *
 * @class OddIntegerParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~IntegerParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {Number} settings.defval - Default value of parameter
 * @param {Number} [settings.min=Number.MIN_SAFE_INTEGER] - Minimum value
 * @param {Number} [settings.max=Number.MAX_SAFE_INTEGER] - Maximum value
 * @param {Number} [settings.value] - Value of parameter
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var OddIntegerParameter = function(___settings) {

  //var that = this;

  ////////////
  ////////////
  //
  // IntegerParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined )
    ___settings.isValid = true;
  // if we are not operating in strict mode, accept strings instead of numbers
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    ['decimalplaces', 'min', 'max', 'defval', 'value'].forEach( function(key) {
      if ( typeof ___settings[key] === 'string' ) {
        ___settings[key] = parseInt(___settings[key]);
      }
    });
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 )
    ___settings.name = 'Even integer parameter';
  // max and min value
  if ( ___settings.min === undefined || typeof ___settings.min !== 'number' ) {
    ___settings.min = Number.MIN_SAFE_INTEGER;
  }
  if ( ___settings.max === undefined || typeof ___settings.max !== 'number' ) {
    ___settings.max = Number.MAX_SAFE_INTEGER;
  }
  if ( ___settings.min > ___settings.max ) {
    ___settings.min = ___settings.max;
    ___settings.isValid = false;
  }
  // defval
  if ( ___settings.defval === undefined || typeof ___settings.defval !== 'number' || ___settings.defval < ___settings.min || ___settings.defval > ___settings.max || Math.abs(___settings.defval % 2) !== 1 ) {
    ___settings.defval = ___settings.min;
    ___settings.isValid = false;
  }
  // value
  if ( ___settings.value === undefined || typeof ___settings.value !== 'number' || ___settings.value < ___settings.min || ___settings.value > ___settings.max || Math.abs(___settings.value % 2) !== 1 )
    ___settings.value = ___settings.defval;


  // inject IntegerParameter
  IntegerParameter.call(this, ___settings);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  var _super_checkValue = this.checkValue;
  /**
   * Checks if given value qualifies as a valid parameter value (odd integer).
   * @param  {Number} value - value to check
   * @return {Boolean}   True if value is a valid parameter value (odd integer).
   */
  this.checkValue = function(value) {
    if ( !_super_checkValue(value) )
      return false;
    return ( Math.abs(value % 2) === 1 );
  };

  return this;
};
module.exports['OddIntegerParameter'] = OddIntegerParameter;


/**
 * Constructor of the ValueListParameter class
 *
 * @classdesc Value list parameter - single choice
 *
 * This is represented by an integer parameter, storing the index of the current
 * list item. It accepts integers as values and returns an integer as the current
 * value.
 *
 * @class ValueListParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~IntegerParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {Number} [settings.defval] - Default value of parameter (index into choices)
 * @param {Array} settings.choices - List of possible choices for the parameter value
 * @param {Number} [settings.value] - Value of parameter (index into choices)
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var ValueListParameter = function(___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // IntegerParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined ) {
    ___settings.isValid = true;
  }
  // if we are not operating in strict mode, accept strings instead of numbers
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    ['defval'].forEach( function(key) {
      if ( typeof ___settings[key] === 'string' ) {
        ___settings[key] = parseInt(___settings[key]);
      }
    });
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 ) {
    ___settings.name = 'Value list parameter';
  }
  // choices
  if ( ___settings.choices === undefined || !Array.isArray(___settings.choices) ) {
    ___settings.choices = [];
    ___settings.isValid = false;
  }
  // max and min value
  ___settings.min = 0;
  ___settings.max = ___settings.choices.length - 1;
  // defval
  if ( ___settings.defval === undefined || typeof ___settings.defval !== 'number' || ___settings.defval < ___settings.min || ___settings.defval > ___settings.max ) {
    ___settings.defval = ___settings.min;
    ___settings.isValid = false;
  }


  // inject IntegerParameter
  IntegerParameter.call(this, ___settings);

  // prevent update of min
  this.registerHook('min',() => false);

  // prevent update of max
  this.registerHook('max',() => false);

  // prevent update of choices
  this.registerHook('choices',() => false);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  /**
   * Get array of choices for this parameter
   * @return {String[]} Array of choice descriptions
   */
  this.getChoices = function() {
    return that.getSetting('choices');
  };

  /**
   * Get current choice
   * @return {String} Current choice
   */
  this.getSelectedChoice = function() {
    return that.getSetting('choices')[ that.getSettingShallow('value') ];
  };


  /**
   * Get definition of paramater
   * @return {module:JSONParameter~JSONParameter}   Definition of parameter if available, undefined otherwise
   */
  this.getDefinition = function() {
    return that.getSettings([
      'id',
      'name',
      'plugin',
      'note',
      'type',
      'choices',
      'defval',
      'group',
      'visualization',
      '_name',
      'order',
      'hidden'
    ]);
  };

  return this;
};
module.exports['ValueListParameter'] = ValueListParameter;


/**
 * Constructor of the MultipleChoiceParameter class
 *
 * @classdesc Value list parameter - multiple choices - choice may be empty
 *
 * This is represented by an array of integers, storing the index of the current
 * list items. It accepts single integers or arrays of integers as values and returns
 * an array of integers as the current value.
 *
 * @class MultipleChoiceParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~CommonParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {Number} [settings.defval] - Default value of parameter (array of indices into choices)
 * @param {Array} settings.choices - List of possible choices for the parameter value
 * @param {Number} [settings.value] - Value of parameter (array of indices into choices)
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var MultipleChoiceParameter = function(___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // CommonParameter mixin
  //
  ////////////
  ////////////

  /**
   * Helper function for checking whether a given value qualifies as a valid parameter value.
   * @param {*} value - value to check
   * @param {Number} max - maximum allowable number
   * @param {Boolean} [bStrict=true] - if false, allow strings and try to parse them
   * @return {Number[]} checked and parsed array of numbers, undefined on error
   */
  var _checkValue = function(value, max, bStrict) {
    var scope = 'MultipleChoiceParameter._checkValue';
    if ( bStrict === undefined ) {
      bStrict = true;
    }
    if ( typeof value === 'string' && !bStrict ) {
      if ( !value.startsWith('[') && value.includes(',') ) {
        try {
          value = JSON.parse( '[' + value + ']' );
        } catch (e) {
          that.debug(scope, e);
          return;
        }
      } else {
        value = parseInt(value);
        if ( isNaN(value) ) {
          return;
        }
      }
    }
    if ( typeof value === 'number' ) {
      if ( value >= 0 && value <= max ) {
        return [value];
      }
    }
    else if ( Array.isArray(value) ) {
      let arr = [];
      let result = value.every( function(val, idx) {
        if ( typeof val === 'string' && !bStrict ) {
          val = parseInt(val);
          if ( !isNaN(val) ) {
            return false;
          }
          value[idx] = val;
        }
        if ( typeof val === 'number' ) {
          if ( val >= 0 && val <= max ) {
            if ( arr.indexOf(val) === -1 ) {
              arr.push(val);
              return true;
            }
          }
        }
        return false;
      });
      if ( result ) {
        // check for multiple indices
        return arr;
      }
    }
  };


  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined ) {
    ___settings.isValid = true;
  }
  // if we are not operating in strict mode, accept strings instead of numbers
  var _strictMode = true;
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    _strictMode = false;
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 ) {
    ___settings.name = 'Multiple value list parameter';
  }
  // choices
  if ( ___settings.choices === undefined || !Array.isArray(___settings.choices) ) {
    ___settings.choices = [];
    ___settings.isValid = false;
  }
  // max and min value
  ___settings.min = 0;
  ___settings.max = ___settings.choices.length - 1;
  // defval
  var _allowString = typeof ___settings.defval === 'string' && !_strictMode;
  if ( !_allowString && ( ___settings.defval === undefined || ( typeof ___settings.defval !== 'number' && !Array.isArray(___settings.defval) ) ) ) {
    ___settings.defval = [];
    ___settings.isValid = false;
  }
  else {
    ___settings.defval = _checkValue(___settings.defval, ___settings.max, _strictMode);
    if ( ___settings.defval === undefined ) {
      ___settings.defval = [];
      ___settings.isValid = false;
    }
  }
  // value
  if ( ___settings.value === undefined ) {
    ___settings.value = ___settings.defval;
  }
  if ( typeof ___settings.value !== 'number' && !Array.isArray(___settings.value) ) {
    ___settings.value = ___settings.defval;
    ___settings.isValid = false;
  }
  else {
    ___settings.value = _checkValue(___settings.value, ___settings.max, _strictMode);
    if ( ___settings.value === undefined ) {
      ___settings.value = ___settings.defval;
      ___settings.isValid = false;
    }
  }

  // inject CommonParameter
  CommonParameter.call(this, ___settings);

  // prevent update of min
  this.registerHook('min',() => false);

  // prevent update of max
  this.registerHook('max',() => false);

  // prevent update of choices
  this.registerHook('choices',() => false);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  /**
   * Get array of choices for this parameter
   * @return {String[]} Array of choice descriptions
   */
  this.getChoices = function() {
    return that.getSetting('choices');
  };

  /**
   * Get currently selected choices
   * @return {String[]} Array of currently selected choices, may be empty
   */
  this.getSelectedChoices = function() {
    var choices = that.getSetting('choices');
    var selChoices = [];
    that.getSettingShallow('value').forEach( function(idx) {
      selChoices.push( choices[idx] );
    });
    return selChoices;
  };

  /**
   * Checks if given value qualifies as a valid parameter value.
   * @param  {*} value - value to check
   * @return {Boolean}   True if value is a valid parameter value.
   */
  this.checkValue = function(value) {
    return undefined !== _checkValue(value, that.getSettingShallow('max'), that.getSettingShallow('strictMode'));
  };

  /**
   * Retrieves the current parameter value as a properly formatted string
   * @return {String} String representation of the current value
   */
  this.getValueString = function() {
    let val = JSON.stringify(that.getValue(), null, 0);
    val = val.replace('[','');
    val = val.replace(']','');
    return val;
  };

  var _super_setValue = this.setValue;
  /**
   * Set new multiple choice value(s)
   * @param  {Number[]|Number} value - The new multiple choice value(s)
   * @return {Boolean}         True if new value is valid and could be set
   */
  this.setValue = function(value) {
    var val = _checkValue(value, that.getSettingShallow('max'), that.getSettingShallow('strictMode'));
    if ( val === undefined )
      return false;
    return _super_setValue(val);
  };

  /**
   * Set parameter value to new value from a compatible string representation
   * @param  {String} value - The new parameter value represented by a string
   * @return {Boolean}      True if new value is valid and could be set
   */
  this.parseValue = function(str) {
    var value = _checkValue(str, that.getSettingShallow('max'), false);
    return that.setValue(value);
  };

  /**
   * Get definition of paramater
   * @return {module:JSONParameter~JSONParameter}   Definition of parameter if available, undefined otherwise
   */
  this.getDefinition = function() {
    return that.getSettings([
      'id',
      'name',
      'plugin',
      'note',
      'type',
      'choices',
      'defval',
      'group',
      'visualization',
      '_name',
      'order',
      'hidden'
    ]);
  };

  return this;
};
module.exports['MultipleChoiceParameter'] = MultipleChoiceParameter;


/**
 * Constructor of the StringParameter class
 *
 * @classdesc  A string parameter
 *
 * @class StringParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~GenericParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {String} [settings.defval=""] - Default value of parameter
 * @param {String} [settings.value] - Value of parameter
 * @param {Number} [settings.max=Number.MAX_SAFE_INTEGER] - Maximum length of string
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var StringParameter = function(___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // CommonParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // valuetype must be string
  ___settings.valuetype = 'string';
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined ) {
    ___settings.isValid = true;
  }
  // if we are not operating in strict mode, accept strings instead of numbers
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    ['max'].forEach( function(key) {
      if ( typeof ___settings[key] === 'string' ) {
        ___settings[key] = parseInt(___settings[key]);
      }
    });
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 ) {
    ___settings.name = 'String parameter';
  }
  // maximum length
  if ( ___settings.max === undefined || typeof ___settings.max !== 'number' ) {
    ___settings.max = Number.MAX_SAFE_INTEGER;
    ___settings.isValid = false;
  }
  // default value
  if ( ___settings.defval === undefined || typeof ___settings.defval !== 'string' ) {
    ___settings.defval = '';
    ___settings.isValid = false;
  }
  if ( ___settings.defval.length > ___settings.max ) {
    ___settings.defval = ___settings.defval.slice(0, ___settings.max);
    ___settings.isValid = false;
  }
  // value
  if ( ___settings.value !== undefined && typeof ___settings.value !== 'string' ) {
    ___settings.value = ___settings.defval;
    ___settings.isValid = false;
  }
  if ( ___settings.value !== undefined && ___settings.value.length > ___settings.max ) {
    ___settings.value = ___settings.value.slice(0, ___settings.max);
    ___settings.isValid = false;
  }


  // inject CommonParameter
  CommonParameter.call(this, ___settings);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  var _super_checkValue = this.checkValue;
  /**
  * Checks if given string qualifies as a valid parameter value.
  * @param  {String} value - string to check
  * @return {Boolean}   True if string is a valid parameter value.
  */
  this.checkValue = function(value) {
    if ( !_super_checkValue(value) )
      return false;
    return ( value.length <= that.getSettingShallow('max') );
  };


  /**
  * Get definition of paramater
  * @return {module:JSONParameter~JSONParameter}   Definition of parameter if available, undefined otherwise
  */
  this.getDefinition = function() {
    return that.getSettings([
      'id',
      'name',
      'plugin',
      'note',
      'type',
      'defval',
      'group',
      'max',
      '_name',
      'order',
      'hidden'
    ]);
  };

  return this;
};
module.exports['StringParameter'] = StringParameter;


/**
 * Constructor of the BooleanParameter class
 *
 * @classdesc  A boolean parameter
 *
 * @class BooleanParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~GenericParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {Boolean} settings.defval - Default value of parameter
 * @param {Boolean} [settings.value] - Value of parameter
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var BooleanParameter = function(___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // CommonParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // valuetype must be boolean
  ___settings.valuetype = 'boolean';
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined ) {
    ___settings.isValid = true;
  }
  // if we are not operating in strict mode, accept strings instead of numbers
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    if ( typeof ___settings.defval === 'string' ) {
      if ( ___settings.defval.toLowerCase() === 'false' )
        ___settings.defval = false;
      else if ( ___settings.defval.toLowerCase() === 'true' )
        ___settings.defval = true;
      else if ( parseInt(___settings.defval) <= 0 )
        ___settings.defval = false;
      else if ( parseInt(___settings.defval) > 0 )
        ___settings.defval = true;
    }
  }
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 ) {
    ___settings.name = 'Boolean parameter';
  }
  // default value
  if ( ___settings.defval === undefined || typeof ___settings.defval !== 'boolean' ) {
    ___settings.defval = false;
    ___settings.isValid = false;
  }


  // inject CommonParameter
  CommonParameter.call(this, ___settings);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  var _super_checkValue = this.checkValue;
  /**
   * Checks if given value qualifies as a valid parameter value.
   * @param  {Boolean} value - boolean value to check
   * @return {Boolean}   True if value is a valid parameter value.
   */
  this.checkValue = function(value) {
    if ( typeof value === 'string' && !that.getSettingShallow('strictMode') ) {
      if (value.toLowerCase() === 'true')
        value = true;
      else if (value.toLowerCase() === 'false')
        value = false;
      else
        return false;
    }
    return _super_checkValue(value);
  };

  var _super_setValue = this.setValue;
  /**
   * Set new boolean value
   * @param  {Boolean} value - The new boolean value
   * @return {Boolean}         True if new value is valid and could be set
   */
  this.setValue = function(value) {
    if ( typeof value === 'string' && !that.getSettingShallow('strictMode') ) {
      if (value.toLowerCase() === 'true')
        value = true;
      else if (value.toLowerCase() === 'false')
        value = false;
      else
        return false;
    }
    return _super_setValue(value);
  };

  /**
   * Get definition of paramater
   * @return {module:JSONParameter~JSONParameter}   Definition of parameter if available, undefined otherwise
   */
  this.getDefinition = function() {
    return that.getSettings([
      'id',
      'name',
      'plugin',
      'note',
      'type',
      'defval',
      'group',
      '_name',
      'order',
      'hidden'
    ]);
  };

  return this;
};
module.exports['BooleanParameter'] = BooleanParameter;


/**
 * Constructor of the ColorParameter class
 *
 * @classdesc A color parameter
 *
 * Internally the color is stored using a string representation
 *
 * @class ColorParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~StringParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {String|Object} [settings.defval="white"] - Default value of parameter - will be converted to a tinycolor object if not already
 * @param {String|Object} [settings.value] - Value of parameter - will be converted to a tinycolor object if not already
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var ColorParameter = function(___settings) {

  var that = this;

  /**
   * Convert to internal string format from tinyColor
   */
  var _toIntFmt = function(c) {
    return c.toString('hex8').replace('#','0x');
  };

  ////////////
  ////////////
  //
  // CommonParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  ___settings.max = Number.MAX_SAFE_INTEGER;
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined )
    ___settings.isValid = true;
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 )
    ___settings.name = 'Color parameter';
  // default value
  if ( ___settings.defval === undefined )
    ___settings.defval = 'white';
  var _tc = toTinyColor(___settings.defval);
  if ( _tc === null ) {
    ___settings.defval = toTinyColor('white');
    ___settings.isValid = false;
  } else {
    ___settings.defval = _tc;
  }
  ___settings.defval = _toIntFmt(___settings.defval);
  // value
  if ( ___settings.value === undefined ) {
    ___settings.value = ___settings.defval;
  } else {
    _tc = toTinyColor(___settings.defval);
    if ( _tc === null ) {
      ___settings.value = toTinyColor('white');
      ___settings.isValid = false;
    } else {
      ___settings.value = _tc;
    }
  }
  ___settings.value = _toIntFmt(___settings.value);

  // inject StringParameter
  StringParameter.call(this, ___settings);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  var _super_setValue = this.setValue;
  /**
   * Set new color value
   * @param  {Object|String} value - The new color value represented by a string, or a tinycolor object
   * @return {Boolean}         True if new color is valid and could be set
   */
  this.setValue = function(value) {
    var tc = toTinyColor(value);
    if ( tc === null )
      return false;
    return _super_setValue(_toIntFmt(tc));
  };

  /**
   * Retrieves the current color value as hex string (e.g. 0xaabbccff)
   * @return {String} The current color value as hex string (e.g. 0xaabbccff)
   */
  this.getValue = function() {
    return _toIntFmt( toTinyColor( that.getSettingShallow('value') ) );
  };

  /**
   * Retrieves a tinycolor object of the current color value {@link https://www.npmjs.com/package/tinycolor2}
   * @return {String} The current color value as a tinycolor object
   */
  this.getValueTinyColor = function() {
    return toTinyColor( that.getSettingShallow('value') );
  };

  /**
   * Retrieves the current color value as hex string (e.g. 0xaabbccff)
   * @return {String} The current color value as hex string (e.g. 0xaabbccff)
   */
  this.getValueString = function() {
    return that.getValue();
  };

  /**
   * Set color value to new color from a compatible string representation, or a tinycolor object
   * @param  {String|Object} value - The new color value represented by a string, or a tinycolor object
   * @return {Boolean}      True if new color is valid and could be set
   */
  this.parseValue = function(str) {
    return that.setValue(str);
  };

  /**
   * Get definition of paramater
   * @return {module:JSONParameter~JSONParameter}   Definition of parameter if available, undefined otherwise
   */
  this.getDefinition = function() {
    return that.getSettings([
      'id',
      'name',
      'plugin',
      'note',
      'type',
      'defval',
      'group',
      '_name',
      'order',
      'hidden'
    ]);
  };

  return this;
};
module.exports['ColorParameter'] = ColorParameter;


/**
 * Constructor of the FileParameter class
 *
 * @classdesc A file parameter
 *
 * The file id or url is stored using a string representation
 *
 * @class FileParameter
 * @implements {module:ParameterInterface~ParameterInterface}
 * @extends module:ShapeDiverParameters~StringParameter
 *
 * @param {Object} settings - Generic parameter settings
 * @param {String} settings.name - Name of parameter
 * @param {String} [settings.value] - Value of parameter (id of file, or url)
 * @param {String} [settings.defval] - Default value of parameter (id of file, or url)
 * @param {Number} [settings.max] - Maximum file size in bytes
 * @param {String[]} [settings.format] - Allowed file mime types
 * @param {String} [settings.note] - description of the parameter
 * @param {String} [settings.group] - group of the parameter for visualization purposes
 */
var FileParameter = function(___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // CommonParameter mixin
  //
  ////////////
  ////////////

  // ensure we got meaningful default settings
  if ( ___settings === undefined || typeof ___settings !== 'object' ) {
    ___settings = defaultSettings;
  } else {
    ___settings = GlobalUtils.deepCopy(___settings);
    GlobalUtils.defaults(___settings, defaultSettings);
  }
  // set isValid if it's not yet set
  if ( ___settings.isValid === undefined )
    ___settings.isValid = true;
  // default name
  if ( ___settings.name === undefined || typeof ___settings.name !== 'string' || ___settings.name.length === 0 )
    ___settings.name = 'File parameter';
  // if we are not operating in strict mode, accept strings instead of numbers
  if ( typeof ___settings.strictMode === 'boolean' && !___settings.strictMode ) {
    ['max'].forEach( function(key) {
      if ( typeof ___settings[key] === 'string' ) {
        ___settings[key] = parseInt(___settings[key]);
      }
    });
  }
  // default value
  if ( ___settings.defval === undefined )
    ___settings.defval = '';
  if ( typeof ___settings.defval !== 'string' ) {
    ___settings.defval = '';
    ___settings.isValid = false;
  }
  // value
  if ( ___settings.value === undefined )
    ___settings.value = ___settings.defval;
  if ( typeof ___settings.value !== 'string' ) {
    ___settings.value = '';
    ___settings.isValid = false;
  }
  // max file size
  if ( ___settings.max === undefined || typeof ___settings.max !== 'number' ) {
    ___settings.max = 0;
    ___settings.isValid = false;
  }
  // allowed file formats
  if ( !GlobalUtils.isArrayOfType(___settings.format, 'string') ) {
    ___settings.format = [];
    ___settings.isValid = false;
  }

  // inject StringParameter
  StringParameter.call(this, ___settings);

  ////////////
  ////////////
  //
  // partial implementation of ParameterInterface
  //
  ////////////
  ////////////

  /**
   * Checks if given string qualifies as a valid file id or url.
   */
  var _checkValueFileIdOrUrl = function(value) {
    if (!GlobalUtils.typeCheck(value, 'string')) {
      return false;
    }
    if (value === '') {
      return true;
    }
    if ( uuidv4Regex.test(value) ) {
      return true;
    }
    // could be done more sophisticated using some regex from https://www.npmjs.com/package/url-regex
    if (value.startsWith('http://') || value.startsWith('https://') ) {
      return true;
    }
    return false;
  };

  /**
   * Checks if given value qualifies as a valid parameter value.
   * @param  {File|String} value - value to check
   * @return {Boolean}   True if value is a valid parameter value.
   */
  this.checkValue = function(value) {
    return _checkValueFileIdOrUrl(value);
  };

  var _super_setValue = this.setValue;
  /**
   * Set new file id or url value
   * @param  {String} value - The new value (file id or url)
   * @return {Boolean}         True if new value is valid and could be set
   */
  this.setValue = function(value) {
    if (!_checkValueFileIdOrUrl(value)) {
      return false;
    }
    return _super_setValue(value);
  };

  /**
  * Check if parameter accepts blobs
  */
  this.acceptsBlobs = function() {
    return true;
  };

  /**
   * Get definition of paramater
   * @return {module:JSONParameter~JSONParameter}   Definition of parameter if available, undefined otherwise
   */
  this.getDefinition = function() {
    return that.getSettings([
      'id',
      'name',
      'plugin',
      'note',
      'type',
      'defval',
      'group',
      '_name',
      'order',
      'hidden',
      'max',
      'format'
    ]);
  };

  return this;
};
module.exports['FileParameter'] = FileParameter;

// #SS-103 missing: date and time parameter



/**
 * Constructor of the ParameterFactory class
 *
 * @classdesc  A factory for parameters
 *
 * @class ParameterFactory
 * @param {Object} settings - Generic settings used for all parameters generated
 */
var ParameterFactory = function(___settings) {

  var that = this;

  // mixins
  require('../mixins/GlobalMixin').call(this);
  require('../mixins/SettingsMixin').call(this, ___settings);
  require('../mixins/LoggingMixin').call(this);

  /**
   * Settings to use for all plugins
   */
  var _parameterDefaultSettings = GlobalUtils.deepCopy(___settings);

  /**
   * Mapping table from {@link module:JSONParameter~ParameterType} to parameter class deriving from {@link module:ShapeDiverParameters~CommonParameter}
   */
  var _parameterTypeMapping = {
    'float': FloatParameter,
    'int': IntegerParameter,
    'even': EvenIntegerParameter,
    'odd': OddIntegerParameter,
    'string': StringParameter,
    'color': ColorParameter,
    'stringlist:sequence': ValueListParameter,
    'stringlist:cycle': ValueListParameter,
    'stringlist:dropdown': ValueListParameter,
    'stringlist:checklist': MultipleChoiceParameter,
    'bool': BooleanParameter,
    'file': FileParameter
    // missing 'Time'
  };

  /**
   * Given a parameter definition, returns an instantiated parameter object
   *
   * @param {module:JSONParameter~JSONParameter} paramDef - Parameter definition
   * @return {module:ShapeDiverParameters~CommonParameter} instance of a class deriving from CommonParameter on success, undefined on error
   */
  this.produce = function(_paramDef) {
    // deep copy paramDef
    var paramDef = GlobalUtils.deepCopy(_paramDef);
    // logging scope
    var scope = 'ShapeDiverParameters~factory';
    // does the parameter definition have a type
    if ( paramDef.type === undefined || typeof paramDef.type !== 'string' ) {
      that.debug(scope, 'type not defined');
      return;
    }
    // do we have a constructor mapped for the type
    var paramType = _parameterTypeMapping[(paramDef.type + ':' + paramDef.visualization).toLowerCase()];
    if ( paramType === undefined ) {
      paramType = _parameterTypeMapping[paramDef.type.toLowerCase()];
      if ( paramType === undefined ) {
        that.debug(scope, 'type not known: ' + paramDef.type.toLowerCase());
        return;
      }
    }
    // try to construct a parameter for the type
    try {
      if (!paramDef.hasOwnProperty('hidden')) paramDef.hidden = false;
      GlobalUtils.inject( _parameterDefaultSettings, paramDef );
      var p = new paramType(paramDef);
      if ( p.isValid() )
        return p;
      else {
        that.debug(scope, 'created parameter not valid for ' + that.jsify(paramDef) + ': ' + that.jsify(p.getSettings()) + ', try to set strictMode to false' );
      }
    } catch (e) {
      that.debug(scope, 'exception creating parameter', paramDef, e);
      return;
    }
  };

  return this;
};
module.exports['ParameterFactory'] = ParameterFactory;
