"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnapModule = void 0;
var SnapPoint_1 = require("./snapElements/SnapPoint");
var SnapLine_1 = require("./snapElements/SnapLine");
/** @ignore */
var THREE = require("../../externals/three");
var AnchorElement_1 = require("./anchorElements/AnchorElement");
var SnapUtils_1 = require("./SnapUtils");
/**
 * @packageDocumentation
 */
var SnapModule = /** @class */ (function () {
    /**
     * Creates the SnapModule.
     *
     * Cannot be created without the api reference.
     *
     * @param api
     */
    function SnapModule(api) {
        this._eventTypes = {
            /** "drag.start" */
            DRAG_START: 'drag.start',
            /** "drag.move" */
            DRAG_MOVE: 'drag.move',
            /** "drag.end" */
            DRAG_END: 'drag.end',
        };
        this._snapPoints = {};
        this._snapLines = {};
        this._anchors = {};
        this._eventListeners = {};
        if (!api)
            return null;
        this._eventListeners[this._eventTypes.DRAG_START] = {};
        this._eventListeners[this._eventTypes.DRAG_MOVE] = {};
        this._eventListeners[this._eventTypes.DRAG_END] = {};
        this._api = api;
        this._api.scene.addEventListener(this._eventTypes.DRAG_START, this.dragStart.bind(this));
        this._api.scene.addEventListener(this._eventTypes.DRAG_MOVE, this.dragMove.bind(this));
        this._api.scene.addEventListener(this._eventTypes.DRAG_END, this.dragEnd.bind(this));
        this._snapUtils = new SnapUtils_1.SnapUtils();
    }
    /**
     * Add a point which can be snapped to.
     *
     * @param pointDefinition
     * @returns the ID of the created element
     */
    SnapModule.prototype.addSnapPoint = function (pointDefinition) {
        var cleanedInput = this._snapUtils.cleanPointInput(pointDefinition);
        this._snapPoints[pointDefinition.id] = new SnapPoint_1.SnapPoint(cleanedInput);
        return cleanedInput.id;
    };
    /**
     * Remove a point from the list of points which can be snapped to.
     *
     * @param id
     */
    SnapModule.prototype.removeSnapPoint = function (id) {
        delete this._snapPoints[id];
    };
    /**
     * Add a line which can be snapped to.
     *
     * @param lineDefinition
     * @returns the ID of the created element
     */
    SnapModule.prototype.addSnapLine = function (lineDefinition) {
        var cleanedInput = this._snapUtils.cleanLineInput(lineDefinition);
        this._snapLines[lineDefinition.id] = new SnapLine_1.SnapLine(cleanedInput);
        return cleanedInput.id;
    };
    /**
     * Remove a line from the list of lines which can be snapped to.
     *
     * @param id
     */
    SnapModule.prototype.removeSnapLine = function (id) {
        delete this._snapLines[id];
    };
    /**
     * Add an anchor element that can snap to the snap elements.
     *
     * @param anchorDefinition
     * @returns the ID of the created element
     */
    SnapModule.prototype.addAnchorElement = function (anchorDefinition) {
        var cleanedInput = this._snapUtils.cleanAnchorInput(anchorDefinition);
        this._anchors[anchorDefinition.id] = new AnchorElement_1.AnchorElement(cleanedInput);
        return cleanedInput.id;
    };
    /**
     * Remove an anchor element from the list of anchor elements.
     *
     * @param id
     */
    SnapModule.prototype.removeAnchorElement = function (id) {
        delete this._anchors[id];
    };
    /**
     * Add an event listener for the specified type with the specified callback.
     *
     * @param type {@link EVENTTYPE}
     * @param cb
     */
    SnapModule.prototype.addEventListener = function (type, cb) {
        if (!this._eventListeners[type])
            return '';
        var id = this._snapUtils.createRandomId();
        this._eventListeners[type][id] = cb;
        return id;
    };
    /**
     * Remove an event listener.
     *
     * @param id
     */
    SnapModule.prototype.removeEventListener = function (id) {
        delete this._eventListeners[this._eventTypes.DRAG_START][id];
        delete this._eventListeners[this._eventTypes.DRAG_MOVE][id];
        delete this._eventListeners[this._eventTypes.DRAG_END][id];
    };
    Object.defineProperty(SnapModule.prototype, "EVENTTYPE", {
        /**
         * Get all possible event types.
         */
        get: function () {
            return this._eventTypes;
        },
        enumerable: false,
        configurable: true
    });
    SnapModule.prototype.dragStart = function (e) {
        this._startPosition = e.worldPos.clone();
        this._currentObjPos = e.dragPosStartObj3D.clone();
        // forward event with new data
        for (var id in this._eventListeners[this._eventTypes.DRAG_START])
            this._eventListeners[this._eventTypes.DRAG_START][id](e);
    };
    SnapModule.prototype.dragMove = function (e) {
        // step 1: distance from object center to all snap points
        var snaps = [];
        this._snapUtils.snapElements(e, this._startPosition, this._snapPoints, snaps, this._anchors);
        this._snapUtils.sortDistance(snaps);
        this._lastEvent = {
            dragEvent: e,
            snap: false,
        };
        // step 2: select anchor from snapElement with snapping direction (only if two points have same distance)
        var equalSnaps = [snaps[0]];
        for (var i = 1; i < snaps.length; i++)
            if (Number(snaps[0].distance).toFixed(4) === Number(snaps[i].distance).toFixed(4))
                equalSnaps.push(snaps[i]);
        // sort by dot 
        this._snapUtils.sortDot(equalSnaps);
        if (snaps.length > 0 && equalSnaps[0].snap) {
            this._lastEvent = this._snapUtils.eval(e, equalSnaps[0], this._startPosition, this._currentObjPos, this._anchors, this._eventListeners, this._eventTypes, false);
            return;
        }
        else {
            var snapsLines = [];
            // if no positions, do lines
            this._snapUtils.snapElements(e, this._startPosition, this._snapLines, snapsLines, this._anchors);
            this._snapUtils.sortDistance(snapsLines);
            if (snapsLines.length > 0 && snapsLines[0].snap) {
                this._lastEvent = this._snapUtils.eval(e, snapsLines[0], this._startPosition, this._currentObjPos, this._anchors, this._eventListeners, this._eventTypes, true);
                return;
            }
            snaps.concat(snapsLines);
        }
        e.obj.snap = false;
        var newPosition = new THREE.Vector3(e.worldPos.x, e.worldPos.y, e.worldPos.z).sub(this._startPosition).add(this._currentObjPos);
        var diff = newPosition.clone().sub(this._currentObjPos);
        e.obj.position.copy(newPosition);
        e.obj.setRotationFromMatrix(new THREE.Matrix4());
        e.obj.nonRotatedPosition = null;
        this._lastEvent.position = e.worldPos.clone();
        for (var i = 0; i < e.obj.additionalDragObjects.length; i++) {
            e.obj.additionalDragObjects[i].snap = false;
            e.obj.additionalDragObjects[i].position.copy(e.obj.additionalDragObjects[i].additionalDragOriginalPosition.clone().add(diff));
            e.obj.additionalDragObjects[i].nonRotatedPosition = null;
            e.obj.additionalDragObjects[i].setRotationFromMatrix(new THREE.Matrix4());
        }
        // forward event with new data
        for (var id in this._eventListeners[this._eventTypes.DRAG_MOVE])
            this._eventListeners[this._eventTypes.DRAG_MOVE][id](this._lastEvent);
    };
    SnapModule.prototype.dragEnd = function (e) {
        this._lastEvent.dragEvent = e;
        e.obj.snap = false;
        // forward event with new data
        for (var id in this._eventListeners[this._eventTypes.DRAG_END])
            this._eventListeners[this._eventTypes.DRAG_END][id](this._lastEvent);
    };
    return SnapModule;
}());
exports.SnapModule = SnapModule;
